// third-party
// import { FormattedMessage } from "react-intl";

// assets
import { DollarCircle, Shield, WalletMoney } from "iconsax-react";

// type
import { NavItemType } from "types/menu";
import { EPermissionSection } from "utils/permissions/types";

// icons
const icons = {
  formsTable: WalletMoney,
  paymentMethods: WalletMoney,
  disputes: Shield,
  financeCenter: DollarCircle,
};

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const financial: NavItemType = {
  id: "group-forms-tables",
  title: "Payments",
  icon: icons.formsTable,
  type: "group",
  children: [
    // {
    //   id: "finance",
    //   title: "Payment Methods",
    //   type: "item",
    //   url: "/finance/payment-methods",
    //   icon: icons.paymentMethods,
    //   permissions: [EPermissionSection.PAYMENT_METHODS],
    // },
    // {
    //   id: "disputes",
    //   title: "Dispute Center",
    //   type: "item",
    //   url: "/finance/disputes",
    //   icon: icons.disputes,
    //   permissions: [EPermissionSection.PAYMENT_METHODS],
    // },
    {
      id: "finance-center",
      title: "Payments Hub",
      type: "item",
      url: "/finance/center",
      icon: icons.financeCenter,
      permissions: [EPermissionSection.PAYMENT_METHODS],
    },
  ],
};

export default financial;
