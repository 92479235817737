import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { Chip, Skeleton } from "@mui/material";

// material-ui
import {
  Button,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

// third-party
import {
  useTable,
  useFilters,
  useGlobalFilter,
  Column,
  Row,
  HeaderGroup,
  Cell,
} from "react-table";

// project-imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { EmptyTable } from "components/third-party/ReactTable";

import {
  GlobalFilter,
  DefaultColumnFilter,
  renderFilterTypes,
} from "utils/react-table";
import { useFormik } from "formik";
import * as yup from "yup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { Trash } from "iconsax-react";
import { enqueueSnackbar } from "notistack";
import AcConfirmDialog from "components/AcConfirmDialog";
import { EPermissionAction, EPermissionSection } from "utils/permissions/types";
import { usePermissions } from "utils/permissions/usePermissions";
import { useParams } from "react-router";
import usePublishers from "hooks/usePublishers";
import { Box } from "@mui/material";
import countries from "data/countries";
import useVatEntities, {
  VatReport as VatReportType,
} from "hooks/useVatReports";
import { UseQueryResult } from "@tanstack/react-query";
import { AxiosResponse } from "axios";
import { CircularProgress } from "@mui/material";

// ==============================|| REACT TABLE ||============================== //

function ReactTable({
  columns,
  data,
  getVatReports,
}: {
  columns: Column[];
  data: [];
  getVatReports: UseQueryResult<AxiosResponse<VatReportType[], any>, Error>;
}) {
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const initialState = useMemo(
    () => ({ filters: [{ id: "status", value: "" }] }),
    []
  );
  const [addPayoutDrawerOpen, setAddPayoutDrawerOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const vatReportFormik = useFormik({
    initialValues: {
      startDate: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString(),
      endDate: new Date().toISOString(),
      countryCode: "",
    },
    validationSchema: yup.object({
      startDate: yup.date().required("From date is required"),
      endDate: yup.date().required("To date is required"),
      countryCode: yup.string().required("Country of arrival is required"),
    }),
    onSubmit: (values) => {
      createVatReportMutation.mutate(
        {
          startDate: values.startDate,
          endDate: values.endDate,
          countryCode: values.countryCode,
          userId: localStorage.getItem("__ac_user") || "N/A",
        },
        {
          onSuccess: () => {
            enqueueSnackbar("VAT Report Created Successfully!");
            getVatReports.refetch();
            setAddPayoutDrawerOpen(false);
          },
          onError: () => {
            enqueueSnackbar(
              "Something went wrong while creating the VAT report",
              {
                variant: "error",
              }
            );
          },
          onSettled: () => {
            vatReportFormik.setSubmitting(false);
          },
        }
      );
    },
  });

  const vatFormik = useFormik({
    initialValues: {
      countryArrival: "",
      vatNumber: "",
      countryDispatch: "",
      sellerName: "",
    },
    validationSchema: yup.object({
      countryArrival: yup.string().required("Country of arrival is required"),
      vatNumber: yup.string().required("VAT number is required"),
      countryDispatch: yup.string().required("Country of dispatch is required"),
      sellerName: yup.string().required("Seller name is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      // Here you would typically make an API call to add the VAT record
      console.log("New VAT Record:", values);
      // Add to vatRecords array
      createVatEntityMutation.mutate(values, {
        onSuccess: () => {
          resetForm();
          getVatEntities.refetch();
          enqueueSnackbar("VAT Record Added Successfully!");
        },
        onError: () => {
          enqueueSnackbar("Something went wrong while adding the VAT record", {
            variant: "error",
          });
        },
      });
    },
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      filterTypes,
    },
    useGlobalFilter,
    useFilters
  );

  const sortingRow = rows.slice();
  const { hasPermission } = usePermissions();
  const {
    getVatEntities,
    createVatEntityMutation,
    createVatReportMutation,
    deleteVatEntityMutation,
  } = useVatEntities();

  return (
    <>
      <Drawer
        anchor="right"
        open={addPayoutDrawerOpen}
        onClose={() => setAddPayoutDrawerOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: { xs: "100%", sm: "75%", md: "350px" },
            padding: 3,
          },
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Generate VAT Report
        </Typography>
        <Stack>
          <Stack py={1} spacing={4}>
            <Stack spacing={1}>
              <InputLabel>From</InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format="dd/MM/yyyy"
                    value={new Date(vatReportFormik.values.startDate)}
                    onChange={(newValue) =>
                      newValue &&
                      vatReportFormik.setFieldValue("startDate", newValue)
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <InputLabel>To</InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format="dd/MM/yyyy"
                    value={new Date(vatReportFormik.values.endDate)}
                    onChange={(newValue) =>
                      newValue &&
                      vatReportFormik.setFieldValue("endDate", newValue)
                    }
                    disableFuture
                  />
                </LocalizationProvider>
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <InputLabel>Country Arrival</InputLabel>
              <FormControl fullWidth>
                <Select
                  value={vatReportFormik.values.countryCode}
                  placeholder="countryCode"
                  onChange={(e) => {
                    console.log(e.target.value);
                    vatReportFormik.setFieldValue(
                      "countryCode",
                      e.target.value
                    );
                  }}
                  renderValue={(value) => {
                    const selectedCountry = countries.find(
                      (c) => c.twoLetterCode === value
                    );
                    return (
                      (selectedCountry?.name && (
                        <Stack direction="row" spacing={1} alignItems="center">
                          <img
                            src={`https://flagcdn.com/w20/${selectedCountry?.twoLetterCode.toLowerCase()}.png`}
                            alt={`${selectedCountry?.name} flag`}
                            style={{ width: 20, height: "auto" }}
                          />
                          <Typography>{selectedCountry?.name}</Typography>
                        </Stack>
                      )) ||
                      "Select Country"
                    );
                  }}
                  displayEmpty
                >
                  {getVatEntities.data?.data?.map((country) => (
                    <MenuItem key={country.id} value={country.countryArrival}>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <img
                          src={`https://flagcdn.com/w20/${country.countryArrival.toLowerCase()}.png`}
                          alt={`${country.countryArrival} flag`}
                          style={{ width: 20, height: "auto" }}
                        />
                        <Typography>
                          {
                            countries.find(
                              (c) => c.twoLetterCode === country.countryArrival
                            )?.name
                          }
                        </Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => vatReportFormik.handleSubmit()}
                disabled={
                  vatReportFormik.isSubmitting ||
                  !vatReportFormik.isValid ||
                  !vatReportFormik.dirty
                }
              >
                Generate VAT Report
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{ padding: 2 }}
      >
        <Stack direction="row" spacing={2} alignItems="center">
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows as any}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          {getVatReports.isRefetching && <CircularProgress size={20} />}
        </Stack>
        <Stack direction="row" spacing={2}>
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            sx={{
              marginTop: 2,
            }}
          >
            <Box sx={{ minWidth: 300 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Country Arrival (Key)</TableCell>
                    <TableCell align="right">Country Vat Number</TableCell>
                    <TableCell align="right">Country Dispatch</TableCell>
                    <TableCell align="right">Seller Name</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {getVatEntities.data?.data?.map((record, index) => (
                    <TableRow key={index}>
                      <TableCell>
                        <Stack direction="row" spacing={1} alignItems="center">
                          <img
                            src={`https://flagcdn.com/w20/${record.countryArrival.toLowerCase()}.png`}
                            alt={`${record.countryArrival} flag`}
                            style={{ width: 20, height: "auto" }}
                          />
                          <Typography>
                            {
                              countries.find(
                                (country) =>
                                  country.twoLetterCode ===
                                  record.countryArrival
                              )?.name
                            }
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="right">{record.vatNumber}</TableCell>
                      <TableCell align="right">
                        <Stack direction="row" spacing={1} alignItems="center">
                          <img
                            src={`https://flagcdn.com/w20/${record.countryDispatch.toLowerCase()}.png`}
                            alt={`${record.countryDispatch} flag`}
                            style={{ width: 20, height: "auto" }}
                          />
                          <Typography>
                            {
                              countries.find(
                                (country) =>
                                  country.twoLetterCode ===
                                  record.countryDispatch
                              )?.name
                            }
                          </Typography>
                        </Stack>
                      </TableCell>
                      <TableCell align="right">{record.sellerName}</TableCell>
                      <TableCell align="right" className="cursor-pointer">
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() =>
                            deleteVatEntityMutation.mutate(record.id, {
                              onSuccess: () => {
                                getVatEntities.refetch();
                                enqueueSnackbar(
                                  "VAT Record Deleted Successfully!"
                                );
                              },
                              onError: (error) => {
                                enqueueSnackbar(
                                  "Something went wrong while deleting the VAT record",
                                  { variant: "error" }
                                );
                              },
                            })
                          }
                        >
                          <Trash />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow className="h-20">
                    <TableCell>
                      <FormControl
                        fullWidth
                        size="small"
                        error={
                          vatFormik.touched.countryArrival &&
                          Boolean(vatFormik.errors.countryArrival)
                        }
                      >
                        <Select
                          name="countryArrival"
                          size="small"
                          fullWidth
                          placeholder="Country Arrival"
                          value={vatFormik.values.countryArrival}
                          onChange={vatFormik.handleChange}
                          onBlur={vatFormik.handleBlur}
                        >
                          {countries.map((country) => (
                            <MenuItem
                              key={country.code}
                              value={country.twoLetterCode}
                            >
                              <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                              >
                                <img
                                  src={`https://flagcdn.com/w20/${country?.twoLetterCode.toLowerCase()}.png`}
                                  alt={`${country?.name} flag`}
                                  style={{ width: 20, height: "auto" }}
                                />
                                <Typography>{country?.name}</Typography>
                              </Stack>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="vatNumber"
                        size="small"
                        fullWidth
                        placeholder="VAT Number"
                        value={vatFormik.values.vatNumber}
                        onChange={vatFormik.handleChange}
                        onBlur={vatFormik.handleBlur}
                        error={
                          vatFormik.touched.vatNumber &&
                          Boolean(vatFormik.errors.vatNumber)
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        name="countryDispatch"
                        size="small"
                        fullWidth
                        placeholder="Country Dispatch"
                        value={vatFormik.values.countryDispatch}
                        onChange={vatFormik.handleChange}
                        onBlur={vatFormik.handleBlur}
                      >
                        {countries.map((country) => (
                          <MenuItem
                            key={country.code}
                            value={country.twoLetterCode}
                          >
                            <Stack
                              direction="row"
                              spacing={1}
                              alignItems="center"
                            >
                              <img
                                src={`https://flagcdn.com/w20/${country?.twoLetterCode.toLowerCase()}.png`}
                                alt={`${country?.name} flag`}
                                style={{ width: 20, height: "auto" }}
                              />
                              <Typography>{country?.name}</Typography>
                            </Stack>
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TextField
                        name="sellerName"
                        size="small"
                        fullWidth
                        placeholder="Seller Name"
                        value={vatFormik.values.sellerName}
                        onChange={vatFormik.handleChange}
                        onBlur={vatFormik.handleBlur}
                        error={
                          vatFormik.touched.sellerName &&
                          Boolean(vatFormik.errors.sellerName)
                        }
                      />
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={() => vatFormik.handleSubmit()}
                        disabled={
                          vatFormik.isSubmitting ||
                          !vatFormik.isValid ||
                          !vatFormik.dirty
                        }
                      >
                        Add
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Popover>
          <Button
            variant="outlined"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            disabled={
              !hasPermission(
                EPermissionSection.VAT_REPORTS,
                EPermissionAction.CREATE
              )
            }
          >
            Country VAT Table
          </Button>
          <Button
            variant="contained"
            onClick={() => setAddPayoutDrawerOpen(true)}
            disabled={
              !hasPermission(
                EPermissionSection.VAT_REPORTS,
                EPermissionAction.CREATE
              )
            }
          >
            Generate VAT Report
          </Button>
        </Stack>
      </Stack>

      <Table {...getTableProps()}>
        <TableHead sx={{ borderTopWidth: 2 }}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup) => (
                <TableCell
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {/* {headerGroups.map((group: HeaderGroup<{}>) => (
            <TableRow {...group.getHeaderGroupProps()}>
              {group.headers.map((column: HeaderGroup) => (
                <TableCell
                  style={{ width: "calc(100% / 7)" }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.canFilter ? column.render("Filter") : null}
                </TableCell>
              ))}
            </TableRow>
          ))} */}
          {sortingRow.length > 0 ? (
            sortingRow.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell: Cell) => (
                    <TableCell
                      {...cell.getCellProps([
                        { className: cell.column.className },
                      ])}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <EmptyTable msg="No VAT Reports" colSpan={columns.length} />
          )}
        </TableBody>
      </Table>
    </>
  );
}

// ==============================|| REACT TABLE - FILTERING ||============================== //

const VatReport = () => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState("");
  const {
    getVatEntities,
    getVatReports,
    deleteVatReportMutation,
    useDownloadVatReport,
  } = useVatEntities();

  const { data: downloadVatReportData } =
    useDownloadVatReport(selectedReportId);

  // Add polling when there are in-progress reports
  useEffect(() => {
    const hasInProgressReports = getVatReports.data?.data?.some(
      (report) => report.status === "IN_PROGRESS"
    );

    if (hasInProgressReports) {
      const pollingInterval = setInterval(() => {
        getVatReports.refetch();
      }, 5000); // Poll every 5 seconds

      return () => clearInterval(pollingInterval);
    }
  }, [getVatReports.data]);

  const deleteReport = async (reportId: string) => {
    deleteVatReportMutation.mutate(reportId, {
      onSuccess: () => {
        getVatReports.refetch();
        enqueueSnackbar("VAT Report Deleted Successfully!");
      },
      onError: () => {
        enqueueSnackbar("Something went wrong while deleting the VAT report", {
          variant: "error",
        });
      },
    });
  };

  useEffect(() => {
    if (downloadVatReportData?.data) {
      window.open(downloadVatReportData?.data, "_blank");
      setSelectedReportId("");
    }
  }, [downloadVatReportData]);

  const columns = useMemo(
    () =>
      [
        // {
        //   Header: "Creation Date",
        //   accessor: "creationDate",
        //   Cell: ({ value }: { value: any }) => {
        //     return new Date(value).toLocaleDateString("en-UK");
        //   },
        // },
        {
          Header: "Report Date Range",
          accessor: "interval",
          Cell: ({ value }: { value: any }) => {
            return `${new Date(value.startDate).toLocaleDateString(
              "en-UK"
            )} - ${new Date(value.endDate).toLocaleDateString("en-UK")}`;
          },
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: ({ value }: { value: any }) => {
            let color:
              | "default"
              | "primary"
              | "secondary"
              | "error"
              | "info"
              | "success"
              | "warning";
            let label: string;

            switch (value) {
              case "CREATED":
                color = "success";
                label = "Created";
                break;
              case "IN_PROGRESS":
                color = "warning";
                label = "Processing";
                break;
              default:
                color = "error";
                label = "Failed";
            }

            return (
              <Chip label={label} color={color} variant="light" size="small" />
            );
          },
        },
        {
          Header: "Country",
          accessor: "countryCode",
          Cell: ({ value }: { value: any }) => {
            return (
              <Stack direction="row" spacing={1} alignItems="center">
                <img
                  src={`https://flagcdn.com/w20/${
                    value.length === 3
                      ? countries
                          .find((c) => c.threeLetterCode === value)
                          ?.twoLetterCode?.toLowerCase()
                      : value.toLowerCase()
                  }.png`}
                  alt={`${value} flag`}
                  style={{ width: 20, height: "auto" }}
                />
                <Typography>
                  {
                    countries.find((country) =>
                      value.length === 3
                        ? country.threeLetterCode === value
                        : country.twoLetterCode === value
                    )?.name
                  }
                </Typography>
              </Stack>
            );
          },
        },
        {
          Header: "Created By",
          accessor: "userId",
        },
        {
          Header: "Download",
          accessor: "",
          filter: false,
          Filter: <></>,
          Cell: ({ row }: { row: Row }) => (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => {
                setSelectedReportId((row.original as any).id);
              }}
              disabled={(row.original as any)?.status !== "CREATED"}
            >
              Download
            </Button>
          ),
        },
      ] as Column[],
    []
  );

  return (
    <MainCard content={false}>
      <AcConfirmDialog
        handleClose={() => setIsDeleteDialogOpen(false)}
        open={isDeleteDialogOpen}
        title="Are you sure you want to delete this VAT Report?"
        confirmText="Delete"
        onConfirm={() => deleteReport(selectedReportId)}
      />
      <ScrollX>
        {getVatReports.isLoading || getVatEntities.isLoading ? (
          <Table>
            <TableBody>
              {[...Array(5)].map((_, rowIndex) => (
                <TableRow key={rowIndex}>
                  {[...Array(5)].map((_, cellIndex) => (
                    <TableCell key={cellIndex}>
                      <Skeleton variant="rounded" width="100%" height={30} />
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <ReactTable
            columns={columns}
            data={((getVatReports?.data as any)?.data || []) as any}
            getVatReports={getVatReports}
          />
        )}
      </ScrollX>
    </MainCard>
  );
};

export default VatReport;
