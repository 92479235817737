import { useQuery } from "@tanstack/react-query";
import { EQuery } from "enum/queries.enum";
import axiosServices from "utils/axios";

export interface PaymentMethod {
  id: string;
  paymentMethodName: string;
  paymentMethodDisplayName: string;
  paymentMethodAssetUrl: string;
  provider: string;
  enabled: boolean;
  pspSubMidId?: string; // Optional since not all entries have this field
}

const fetchPaymentMethods = async () => {
  return await axiosServices.get<PaymentMethod[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/payment-methods`
  );
};

export default function usePaymentMethods() {
  const getPaymentMethods = useQuery({
    queryKey: [EQuery.GET_PAYMENT_METHODS],
    queryFn: () => fetchPaymentMethods(),
  });

  return { getPaymentMethods };
}
