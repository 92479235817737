import { useQuery } from "@tanstack/react-query";
import { EQuery } from "enum/queries.enum";
import axiosServices from "utils/axios";

const fetchCompanies = async () => {
  const response = await axiosServices.get<
    { companyId: string; companyName: string }[]
  >(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/companies`
  );
  return response.data;
};

const fetchCompanyCreds = async (companyId: string) => {
  return await axiosServices.get<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/companies/${companyId}/superuser`
  );
};

export default function useCompanies(companyId?: string) {
  const getCompanies = useQuery<{ companyId: string; companyName: string }[]>({
    queryKey: [EQuery.GET_COMPANIES],
    queryFn: fetchCompanies,
  });

  const getCompanyCreds = useQuery({
    queryKey: [EQuery.GET_COMPANY_CREDS, companyId],
    queryFn: () => fetchCompanyCreds(companyId!),
    enabled: !!companyId, // Conditional fetching
  });

  return { getCompanies, getCompanyCreds };
}
