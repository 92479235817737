import { useMemo, useState } from "react";

// material-ui
import {
  Box,
  Button,
  Fade,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Select,
  Drawer,
  TextField,
  FormControl,
  FormHelperText,
} from "@mui/material";

// third-party
import {
  useTable,
  useFilters,
  useGlobalFilter,
  Column,
  Row,
  HeaderGroup,
  Cell,
} from "react-table";

// project-imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { EmptyTable } from "components/third-party/ReactTable";

import {
  GlobalFilter,
  DefaultColumnFilter,
  renderFilterTypes,
} from "utils/react-table";
import useAdminUsers from "hooks/useAdminUsers";
import { enqueueSnackbar } from "notistack";
import AcConfirmDialog from "components/AcConfirmDialog";
import { EPermissionAction, EPermissionSection } from "utils/permissions/types";
import { usePermissions } from "utils/permissions/usePermissions";
import { More } from "iconsax-react";
import { InputLabel } from "@mui/material";

// ==============================|| REACT TABLE ||============================== //

function ReactTable({
  columns,
  data,
  setAdminUserDrawerOpen,
}: {
  columns: Column[];
  data: [];
  setAdminUserDrawerOpen: Function;
}) {
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const initialState = useMemo(
    () => ({ filters: [{ id: "status", value: "" }] }),
    []
  );
  const { hasPermission } = usePermissions();

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      filterTypes,
    },
    useGlobalFilter,
    useFilters
  );

  const sortingRow = rows.slice(0, 150);

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{ padding: 2 }}
      >
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows as any}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <Button
          variant="contained"
          onClick={() => {
            setAdminUserDrawerOpen(true);
          }}
          size="large"
          disabled={
            !hasPermission(EPermissionSection.USERS, EPermissionAction.CREATE)
          }
        >
          Add Admin User
        </Button>
      </Stack>

      <Table {...getTableProps()}>
        <TableHead sx={{ borderTopWidth: 2 }}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup, index: number) => (
                <TableCell
                  {...column.getHeaderProps([{ className: column.className }])}
                  sx={{ width: index === 2 ? "10%" : "45%" }}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {headerGroups.map((group: HeaderGroup<{}>) => (
            <TableRow {...group.getHeaderGroupProps()}>
              {group.headers.map((column: HeaderGroup, index: number) => (
                <TableCell
                  {...column.getHeaderProps([{ className: column.className }])}
                  sx={{ width: index === 2 ? "10%" : "45%" }}
                >
                  {column.canFilter ? column.render("Filter") : null}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {sortingRow.length > 0 ? (
            sortingRow.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell: Cell, index: number) => (
                    <TableCell
                      {...cell.getCellProps([
                        { className: cell.column.className },
                      ])}
                      sx={{ width: index === 2 ? "10%" : "45%" }}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <EmptyTable msg="No Data" colSpan={7} />
          )}
        </TableBody>
      </Table>
    </>
  );
}

// ==============================|| REACT TABLE - FILTERING ||============================== //

const AdminUsers = () => {
  const [adminUserDrawerOpen, setAdminUserDrawerOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [userRole, setUserRole] = useState("admin");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectUserEmail, setSelectedUserEmail] = useState("");
  const {
    addAdminUserMutation,
    deleteAdminUserMutation,
    updateAdminUserMutation,
    getAdminUsers,
    getAdminRoles,
  } = useAdminUsers();
  const { hasPermission } = usePermissions();

  const handleAddUser = (email: string, role: string) => {
    addAdminUserMutation.mutate(
      { email, role },
      {
        onSuccess: () => {
          enqueueSnackbar("Admin User added successfully");
          setAdminUserDrawerOpen(false);
          getAdminUsers.refetch();
          setUserEmail("");
          setUserRole("admin");
        },
        onError: () => {
          enqueueSnackbar("Admin User couldn't be added", {
            variant: "error",
          });
        },
      }
    );
  };

  const handleDeleteUser = (email: string) => {
    deleteAdminUserMutation.mutate(email, {
      onSuccess: () => {
        enqueueSnackbar("Admin User deleted successfully");
        getAdminUsers.refetch();
      },
      onError: () => {
        enqueueSnackbar("Admin User couldn't be deleted", {
          variant: "error",
        });
      },
    });
  };

  const handleChangeUserRole = (email: string, role: string) => {
    updateAdminUserMutation.mutate(
      { email, role },
      {
        onSuccess: () => {
          enqueueSnackbar(`${email} updated to ${role} successfully`, {
            variant: "success",
          });
          getAdminUsers.refetch();
        },
        onError: () => {
          enqueueSnackbar("Admin User role couldn't be updated", {
            variant: "error",
          });
        },
      }
    );
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: "Email",
          accessor: "userEmail",
          style: { width: "45%" },
        },
        {
          Header: "Role",
          accessor: "userRole",
          style: { width: "45%" },
          Cell: ({ value, row }: { value: string; row: Row }) => (
            <Select
              value={value}
              onChange={(e) => {
                handleChangeUserRole(
                  (row.original as any).userEmail,
                  e.target.value
                );
              }}
              size="small"
              disabled={
                !hasPermission(EPermissionSection.USERS, EPermissionAction.EDIT)
              }
            >
              {getAdminRoles.data?.data.map((role: any) => (
                <MenuItem value={role._id}>{role.presentationName}</MenuItem>
              ))}
            </Select>
          ),
        },
        {
          Header: "Actions",
          accessor: "",
          disableFilters: true,
          Filter: <></>,
          Cell: ({ row }: { row: Row }) => {
            const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
            const open = Boolean(anchorEl);

            const handleClick = (
              event: React.MouseEvent<HTMLButtonElement>
            ) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            };

            const handleClose = (event?: React.MouseEvent<HTMLElement>) => {
              if (event) {
                event.stopPropagation();
              }
              setAnchorEl(null);
            };

            const handleMenuItemClick =
              (action: () => void) => (event: React.MouseEvent) => {
                event.stopPropagation();
                action();
                handleClose();
              };

            return (
              <>
                <IconButton onClick={handleClick}>
                  <More />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(
                    event: {},
                    reason: "backdropClick" | "escapeKeyDown"
                  ) => handleClose()}
                  onClick={(event) => event.stopPropagation()}
                >
                  <MenuItem
                    sx={{ color: "error.main" }}
                    onClick={() => {
                      setSelectedUserEmail((row.original as any).userEmail);
                      setIsDeleteDialogOpen(true);
                    }}
                    disabled={
                      !hasPermission(
                        EPermissionSection.USERS,
                        EPermissionAction.DELETE
                      )
                    }
                  >
                    Delete Role
                  </MenuItem>
                </Menu>
              </>
            );
          },
        },
      ] as Column<object>[],
    [hasPermission]
  );

  return !getAdminUsers.isLoading && !getAdminRoles.isLoading ? (
    <>
      <Fade key={"publisherstitle"} in={true} timeout={500}>
        <Typography variant="h3" mb={3}>
          Users
        </Typography>
      </Fade>
      <AcConfirmDialog
        handleClose={() => setIsDeleteDialogOpen(false)}
        open={isDeleteDialogOpen}
        title={
          <span>
            Are you sure you want to delete{" "}
            <b>
              <em>{selectUserEmail}</em>
            </b>
          </span>
        }
        confirmText="Delete"
        onConfirm={() => {
          handleDeleteUser(selectUserEmail);
          setIsDeleteDialogOpen(false);
        }}
      />
      <Drawer
        anchor="right"
        open={adminUserDrawerOpen}
        onClose={() => setAdminUserDrawerOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: { xs: "100%", sm: "75%", md: "350px" },
            padding: 3,
          },
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Add a new Admin User
        </Typography>
        <Stack>
          <Stack py={1} spacing={4}>
            <Stack spacing={1}>
              <InputLabel>User email</InputLabel>
              <FormControl fullWidth>
                <TextField
                  type="text"
                  value={userEmail}
                  helperText="For example: ops@appcharge.com"
                  onChange={(e) => {
                    setUserEmail(e.target.value);
                  }}
                  InputProps={{
                    endAdornment: "@appcharge.com",
                  }}
                />
              </FormControl>
              <Box mt={2}></Box>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-helper-label">
                  Role
                </InputLabel>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={userRole}
                  disabled={
                    !hasPermission(
                      EPermissionSection.USERS,
                      EPermissionAction.EDIT
                    )
                  }
                  onChange={(e) => {
                    setUserRole(e.target.value);
                  }}
                >
                  <MenuItem disabled value="">
                    <em>Select Role</em>
                  </MenuItem>
                  {getAdminRoles.data?.data.map((role: any) => (
                    <MenuItem value={role._id}>
                      {role.presentationName}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  This will determine the users access to the system, please be
                  careful.
                </FormHelperText>
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  handleAddUser(userEmail + "@appcharge.com", userRole);
                }}
                disabled={
                  !hasPermission(
                    EPermissionSection.USERS,
                    EPermissionAction.CREATE
                  )
                }
              >
                Add Admin User
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
      <Fade key={"publishers"} in={true} timeout={500}>
        <MainCard content={false}>
          <ScrollX>
            <ReactTable
              setAdminUserDrawerOpen={setAdminUserDrawerOpen}
              columns={columns}
              data={(getAdminUsers?.data?.data || []) as any}
            />
          </ScrollX>
        </MainCard>
      </Fade>
    </>
  ) : (
    <Stack spacing={2}>
      {/* Table header skeleton */}
      <Skeleton variant="rounded" height={60} />

      {/* Search bar and button area */}
      <Stack direction="row" justifyContent="space-between" px={2}>
        <Skeleton variant="rounded" width={200} height={40} />
        <Skeleton variant="rounded" width={150} height={40} />
      </Stack>

      {/* Table rows */}
      <Stack>
        {[1, 2, 3, 4, 5].map((_, index) => (
          <Stack key={index} direction="row" spacing={2} p={1}>
            <Skeleton variant="rounded" width="45%" height={40} />
            <Skeleton variant="rounded" width="45%" height={40} />
            <Skeleton variant="rounded" width="10%" height={40} />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default AdminUsers;
