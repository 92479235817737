import { useContext, useMemo, useRef, useState } from "react";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { CircularProgress } from "@mui/material";

// material-ui
import {
  Button,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";

// third-party
import {
  useTable,
  useFilters,
  useGlobalFilter,
  Column,
  Row,
  HeaderGroup,
  Cell,
} from "react-table";

// project-imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { EmptyTable } from "components/third-party/ReactTable";

import {
  GlobalFilter,
  DefaultColumnFilter,
  renderFilterTypes,
} from "utils/react-table";
import { Context } from "App";
import { StoreData } from "types/publisher";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DocumentUpload } from "iconsax-react";
import { enqueueSnackbar } from "notistack";
import { getStoreData } from "utils/apiCalls";
import AcConfirmDialog from "components/AcConfirmDialog";
import { EPermissionAction, EPermissionSection } from "utils/permissions/types";
import { usePermissions } from "utils/permissions/usePermissions";
import { useParams } from "react-router";
import usePublishers from "hooks/usePublishers";

// ==============================|| REACT TABLE ||============================== //

function ReactTable({
  columns,
  data,
  refetchData,
}: {
  columns: Column[];
  data: [];
  refetchData: () => void;
}) {
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const initialState = useMemo(
    () => ({ filters: [{ id: "status", value: "" }] }),
    []
  );
  const [addPayoutDrawerOpen, setAddPayoutDrawerOpen] = useState(false);
  const [transferDate, setTransferDate] = useState(new Date());
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [currency, setCurrency] = useState("USD");
  const [amount, setAmount] = useState("");
  const [uploadedPayoutReport, setUploadedPayoutReport] = useState<File | null>(
    null
  );
  const [isUploading, setIsUploading] = useState(false);
  const [referenceNumber, setReferenceNumber] = useState("");
  const [description, setDescription] = useState("");
  const fileRef = useRef<HTMLInputElement>(null);
  const { globalPublisherData } = useContext<any>(Context);
  const [publisherData, setPublisherData] = globalPublisherData;
  const { id } = useParams();

  const clearFields = () => {
    setTransferDate(new Date());
    setFromDate(new Date());
    setToDate(new Date());
    setCurrency("USD");
    setAmount("");
    setUploadedPayoutReport(null);
    setReferenceNumber("");
    setDescription("");
    fileRef.current!.value = "";
  };

  const submitNewPayout = () => {
    const url = `${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/payouts/${id}`;

    const formData = new FormData();
    formData.append("transferDate", transferDate.toString());
    formData.append("amount", amount);
    formData.append("currency", currency);
    formData.append("period[startDate]", fromDate.toString());
    formData.append("period[endDate]", toDate.toString());
    formData.append("status", "Success");
    formData.append("reference", referenceNumber);
    formData.append("description", description);

    uploadedPayoutReport &&
      formData.append("payoutFile", uploadedPayoutReport as File);

    setIsUploading(true);
    fetch(url, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("__ac_token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        setAddPayoutDrawerOpen(false);
        enqueueSnackbar("New Payout Created!");
        clearFields();
        getStoreData(id as string, (data) => {
          setPublisherData(data);
        });
        refetchData();
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsUploading(false);
      });
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      filterTypes,
    },
    useGlobalFilter,
    useFilters
  );

  const sortingRow = rows.slice(0, 10);
  const { hasPermission } = usePermissions();

  return (
    <>
      <Drawer
        anchor="right"
        open={addPayoutDrawerOpen}
        onClose={() => setAddPayoutDrawerOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: { xs: "100%", sm: "75%", md: "350px" },
            padding: 3,
          },
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Add Payout
        </Typography>
        <Stack>
          <Stack py={1} spacing={4}>
            <Stack spacing={1}>
              <InputLabel>Transfer Date</InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format="dd/MM/yyyy"
                    value={transferDate}
                    onChange={(newValue) =>
                      newValue && setTransferDate(newValue)
                    }
                    disabled={isUploading}
                  />
                </LocalizationProvider>
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <InputLabel>From</InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format="dd/MM/yyyy"
                    value={fromDate}
                    onChange={(newValue) => newValue && setFromDate(newValue)}
                    disabled={isUploading}
                  />
                </LocalizationProvider>
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <InputLabel>To</InputLabel>
              <FormControl sx={{ width: "100%" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    format="dd/MM/yyyy"
                    value={toDate}
                    onChange={(newValue) => newValue && setToDate(newValue)}
                    disabled={isUploading}
                  />
                </LocalizationProvider>
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <InputLabel>Currency</InputLabel>
              <FormControl fullWidth>
                <Select
                  value={currency}
                  placeholder="currency"
                  onChange={(e) => setCurrency(e.target.value)}
                  disabled={isUploading}
                >
                  <MenuItem value="AED">
                    AED - United Arab Emirates Dirham
                  </MenuItem>
                  <MenuItem value="AFN">AFN - Afghan Afghani</MenuItem>
                  <MenuItem value="ALL">ALL - Albanian Lek</MenuItem>
                  <MenuItem value="AMD">AMD - Armenian Dram</MenuItem>
                  <MenuItem value="ANG">
                    ANG - Netherlands Antillean Guilder
                  </MenuItem>
                  <MenuItem value="AOA">AOA - Angolan Kwanza</MenuItem>
                  <MenuItem value="ARS">ARS - Argentine Peso</MenuItem>
                  <MenuItem value="AUD">AUD - Australian Dollar</MenuItem>
                  <MenuItem value="AWG">AWG - Aruban Florin</MenuItem>
                  <MenuItem value="AZN">AZN - Azerbaijani Manat</MenuItem>
                  <MenuItem value="BAM">
                    BAM - Bosnia-Herzegovina Convertible Mark
                  </MenuItem>
                  <MenuItem value="BBD">BBD - Barbadian Dollar</MenuItem>
                  <MenuItem value="BDT">BDT - Bangladeshi Taka</MenuItem>
                  <MenuItem value="BGN">BGN - Bulgarian Lev</MenuItem>
                  <MenuItem value="BHD">BHD - Bahraini Dinar</MenuItem>
                  <MenuItem value="BIF">BIF - Burundian Franc</MenuItem>
                  <MenuItem value="BMD">BMD - Bermudian Dollar</MenuItem>
                  <MenuItem value="BND">BND - Brunei Dollar</MenuItem>
                  <MenuItem value="BOB">BOB - Bolivian Boliviano</MenuItem>
                  <MenuItem value="BRL">BRL - Brazilian Real</MenuItem>
                  <MenuItem value="BSD">BSD - Bahamian Dollar</MenuItem>
                  <MenuItem value="BTN">BTN - Bhutanese Ngultrum</MenuItem>
                  <MenuItem value="BWP">BWP - Botswanan Pula</MenuItem>
                  <MenuItem value="BYN">BYN - Belarusian Ruble</MenuItem>
                  <MenuItem value="BZD">BZD - Belize Dollar</MenuItem>
                  <MenuItem value="CAD">CAD - Canadian Dollar</MenuItem>
                  <MenuItem value="CDF">CDF - Congolese Franc</MenuItem>
                  <MenuItem value="CHF">CHF - Swiss Franc</MenuItem>
                  <MenuItem value="CLP">CLP - Chilean Peso</MenuItem>
                  <MenuItem value="CNY">CNY - Chinese Yuan</MenuItem>
                  <MenuItem value="COP">COP - Colombian Peso</MenuItem>
                  <MenuItem value="CRC">CRC - Costa Rican Colón</MenuItem>
                  <MenuItem value="CUP">CUP - Cuban Peso</MenuItem>
                  <MenuItem value="CVE">CVE - Cape Verdean Escudo</MenuItem>
                  <MenuItem value="CZK">CZK - Czech Koruna</MenuItem>
                  <MenuItem value="DJF">DJF - Djiboutian Franc</MenuItem>
                  <MenuItem value="DKK">DKK - Danish Krone</MenuItem>
                  <MenuItem value="DOP">DOP - Dominican Peso</MenuItem>
                  <MenuItem value="DZD">DZD - Algerian Dinar</MenuItem>
                  <MenuItem value="EGP">EGP - Egyptian Pound</MenuItem>
                  <MenuItem value="ERN">ERN - Eritrean Nakfa</MenuItem>
                  <MenuItem value="ETB">ETB - Ethiopian Birr</MenuItem>
                  <MenuItem value="EUR">EUR - Euro</MenuItem>
                  <MenuItem value="FJD">FJD - Fijian Dollar</MenuItem>
                  <MenuItem value="FKP">FKP - Falkland Islands Pound</MenuItem>
                  <MenuItem value="FOK">FOK - Faroese Króna</MenuItem>
                  <MenuItem value="GBP">GBP - British Pound</MenuItem>
                  <MenuItem value="GEL">GEL - Georgian Lari</MenuItem>
                  <MenuItem value="GGP">GGP - Guernsey Pound</MenuItem>
                  <MenuItem value="GHS">GHS - Ghanaian Cedi</MenuItem>
                  <MenuItem value="GIP">GIP - Gibraltar Pound</MenuItem>
                  <MenuItem value="GMD">GMD - Gambian Dalasi</MenuItem>
                  <MenuItem value="GNF">GNF - Guinean Franc</MenuItem>
                  <MenuItem value="GTQ">GTQ - Guatemalan Quetzal</MenuItem>
                  <MenuItem value="GYD">GYD - Guyanaese Dollar</MenuItem>
                  <MenuItem value="HKD">HKD - Hong Kong Dollar</MenuItem>
                  <MenuItem value="HNL">HNL - Honduran Lempira</MenuItem>
                  <MenuItem value="HRK">HRK - Croatian Kuna</MenuItem>
                  <MenuItem value="HTG">HTG - Haitian Gourde</MenuItem>
                  <MenuItem value="HUF">HUF - Hungarian Forint</MenuItem>
                  <MenuItem value="IDR">IDR - Indonesian Rupiah</MenuItem>
                  <MenuItem value="ILS">ILS - Israeli New Shekel</MenuItem>
                  <MenuItem value="IMP">IMP - Isle of Man Pound</MenuItem>
                  <MenuItem value="INR">INR - Indian Rupee</MenuItem>
                  <MenuItem value="IQD">IQD - Iraqi Dinar</MenuItem>
                  <MenuItem value="IRR">IRR - Iranian Rial</MenuItem>
                  <MenuItem value="ISK">ISK - Icelandic Króna</MenuItem>
                  <MenuItem value="JEP">JEP - Jersey Pound</MenuItem>
                  <MenuItem value="JMD">JMD - Jamaican Dollar</MenuItem>
                  <MenuItem value="JOD">JOD - Jordanian Dinar</MenuItem>
                  <MenuItem value="JPY">JPY - Japanese Yen</MenuItem>
                  <MenuItem value="KES">KES - Kenyan Shilling</MenuItem>
                  <MenuItem value="KGS">KGS - Kyrgystani Som</MenuItem>
                  <MenuItem value="KHR">KHR - Cambodian Riel</MenuItem>
                  <MenuItem value="KID">KID - Kiribati Dollar</MenuItem>
                  <MenuItem value="KMF">KMF - Comorian Franc</MenuItem>
                  <MenuItem value="KRW">KRW - South Korean Won</MenuItem>
                  <MenuItem value="KWD">KWD - Kuwaiti Dinar</MenuItem>
                  <MenuItem value="KYD">KYD - Cayman Islands Dollar</MenuItem>
                  <MenuItem value="KZT">KZT - Kazakhstani Tenge</MenuItem>
                  <MenuItem value="LAK">LAK - Laotian Kip</MenuItem>
                  <MenuItem value="LBP">LBP - Lebanese Pound</MenuItem>
                  <MenuItem value="LKR">LKR - Sri Lankan Rupee</MenuItem>
                  <MenuItem value="LRD">LRD - Liberian Dollar</MenuItem>
                  <MenuItem value="LSL">LSL - Lesotho Loti</MenuItem>
                  <MenuItem value="LYD">LYD - Libyan Dinar</MenuItem>
                  <MenuItem value="MAD">MAD - Moroccan Dirham</MenuItem>
                  <MenuItem value="MDL">MDL - Moldovan Leu</MenuItem>
                  <MenuItem value="MGA">MGA - Malagasy Ariary</MenuItem>
                  <MenuItem value="MKD">MKD - Macedonian Denar</MenuItem>
                  <MenuItem value="MMK">MMK - Myanma Kyat</MenuItem>
                  <MenuItem value="MNT">MNT - Mongolian Tugrik</MenuItem>
                  <MenuItem value="MOP">MOP - Macanese Pataca</MenuItem>
                  <MenuItem value="MRU">MRU - Mauritanian Ouguiya</MenuItem>
                  <MenuItem value="MUR">MUR - Mauritian Rupee</MenuItem>
                  <MenuItem value="MVR">MVR - Maldivian Rufiyaa</MenuItem>
                  <MenuItem value="MWK">MWK - Malawian Kwacha</MenuItem>
                  <MenuItem value="MXN">MXN - Mexican Peso</MenuItem>
                  <MenuItem value="MYR">MYR - Malaysian Ringgit</MenuItem>
                  <MenuItem value="MZN">MZN - Mozambican Metical</MenuItem>
                  <MenuItem value="NAD">NAD - Namibian Dollar</MenuItem>
                  <MenuItem value="NGN">NGN - Nigerian Naira</MenuItem>
                  <MenuItem value="NIO">NIO - Nicaraguan Córdoba</MenuItem>
                  <MenuItem value="NOK">NOK - Norwegian Krone</MenuItem>
                  <MenuItem value="NPR">NPR - Nepalese Rupee</MenuItem>
                  <MenuItem value="NZD">NZD - New Zealand Dollar</MenuItem>
                  <MenuItem value="OMR">OMR - Omani Rial</MenuItem>
                  <MenuItem value="PAB">PAB - Panamanian Balboa</MenuItem>
                  <MenuItem value="PEN">PEN - Peruvian Nuevo Sol</MenuItem>
                  <MenuItem value="PGK">PGK - Papua New Guinean Kina</MenuItem>
                  <MenuItem value="PHP">PHP - Philippine Peso</MenuItem>
                  <MenuItem value="PKR">PKR - Pakistani Rupee</MenuItem>
                  <MenuItem value="PLN">PLN - Polish Zloty</MenuItem>
                  <MenuItem value="PYG">PYG - Paraguayan Guarani</MenuItem>
                  <MenuItem value="QAR">QAR - Qatari Rial</MenuItem>
                  <MenuItem value="RON">RON - Romanian Leu</MenuItem>
                  <MenuItem value="RSD">RSD - Serbian Dinar</MenuItem>
                  <MenuItem value="RUB">RUB - Russian Ruble</MenuItem>
                  <MenuItem value="RWF">RWF - Rwandan Franc</MenuItem>
                  <MenuItem value="SAR">SAR - Saudi Riyal</MenuItem>
                  <MenuItem value="SBD">SBD - Solomon Islands Dollar</MenuItem>
                  <MenuItem value="SCR">SCR - Seychellois Rupee</MenuItem>
                  <MenuItem value="SDG">SDG - Sudanese Pound</MenuItem>
                  <MenuItem value="SEK">SEK - Swedish Krona</MenuItem>
                  <MenuItem value="SGD">SGD - Singapore Dollar</MenuItem>
                  <MenuItem value="SHP">SHP - Saint Helena Pound</MenuItem>
                  <MenuItem value="SLL">SLL - Sierra Leonean Leone</MenuItem>
                  <MenuItem value="SOS">SOS - Somali Shilling</MenuItem>
                  <MenuItem value="SRD">SRD - Surinamese Dollar</MenuItem>
                  <MenuItem value="SSP">SSP - South Sudanese Pound</MenuItem>
                  <MenuItem value="STN">
                    STN - São Tomé and Príncipe Dobra
                  </MenuItem>
                  <MenuItem value="SYP">SYP - Syrian Pound</MenuItem>
                  <MenuItem value="SZL">SZL - Swazi Lilangeni</MenuItem>
                  <MenuItem value="THB">THB - Thai Baht</MenuItem>
                  <MenuItem value="TJS">TJS - Tajikistani Somoni</MenuItem>
                  <MenuItem value="TMT">TMT - Turkmenistani Manat</MenuItem>
                  <MenuItem value="TND">TND - Tunisian Dinar</MenuItem>
                  <MenuItem value="TOP">TOP - Tongan Paʻanga</MenuItem>
                  <MenuItem value="TRY">TRY - Turkish Lira</MenuItem>
                  <MenuItem value="TTD">
                    TTD - Trinidad and Tobago Dollar
                  </MenuItem>
                  <MenuItem value="TVD">TVD - Tuvaluan Dollar</MenuItem>
                  <MenuItem value="TWD">TWD - New Taiwan Dollar</MenuItem>
                  <MenuItem value="TZS">TZS - Tanzanian Shilling</MenuItem>
                  <MenuItem value="UAH">UAH - Ukrainian Hryvnia</MenuItem>
                  <MenuItem value="UGX">UGX - Ugandan Shilling</MenuItem>
                  <MenuItem value="USD">USD - United States Dollar</MenuItem>
                  <MenuItem value="UYU">UYU - Uruguayan Peso</MenuItem>
                  <MenuItem value="UZS">UZS - Uzbekistani Som</MenuItem>
                  <MenuItem value="VES">
                    VES - Venezuelan Bolívar Soberano
                  </MenuItem>
                  <MenuItem value="VND">VND - Vietnamese Dong</MenuItem>
                  <MenuItem value="VUV">VUV - Vanuatu Vatu</MenuItem>
                  <MenuItem value="WST">WST - Samoan Tala</MenuItem>
                  <MenuItem value="XAF">
                    XAF - Central African CFA Franc
                  </MenuItem>
                  <MenuItem value="XCD">XCD - East Caribbean Dollar</MenuItem>
                  <MenuItem value="XDR">XDR - Special Drawing Rights</MenuItem>
                  <MenuItem value="XOF">XOF - West African CFA Franc</MenuItem>
                  <MenuItem value="XPF">XPF - CFP Franc</MenuItem>
                  <MenuItem value="YER">YER - Yemeni Rial</MenuItem>
                  <MenuItem value="ZAR">ZAR - South African Rand</MenuItem>
                  <MenuItem value="ZMW">ZMW - Zambian Kwacha</MenuItem>
                  <MenuItem value="ZWL">ZWL - Zimbabwean Dollar</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <InputLabel>Amount</InputLabel>
              <FormControl fullWidth>
                <TextField
                  type="number"
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  disabled={isUploading}
                />
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <InputLabel>Reference Number</InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={referenceNumber}
                  onChange={(e) => setReferenceNumber(e.target.value)}
                  disabled={isUploading}
                />
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <InputLabel>Description</InputLabel>
              <FormControl fullWidth>
                <TextField
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  disabled={isUploading}
                  multiline
                  rows={3}
                />
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <InputLabel>Upload Payout report</InputLabel>
              <FormControl fullWidth>
                <input
                  type="file"
                  ref={fileRef}
                  onChange={() => {
                    fileRef.current?.files &&
                      setUploadedPayoutReport(fileRef.current?.files[0]);
                  }}
                  hidden
                />
                <TextField
                  value={fileRef.current?.files?.[0]?.name || ""}
                  onClick={() => fileRef.current?.click()}
                  disabled={isUploading}
                  placeholder="Upload Payout Report"
                  InputProps={{
                    startAdornment: (
                      <Button variant="contained" disabled={isUploading}>
                        <DocumentUpload color="white" />
                      </Button>
                    ),
                  }}
                />
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={submitNewPayout}
                disabled={isUploading}
              >
                {isUploading ? <CircularProgress size={20} /> : "Add Payout"}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{ padding: 2 }}
      >
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows as any}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <Button
          variant="contained"
          onClick={() => setAddPayoutDrawerOpen(true)}
          disabled={
            !hasPermission(EPermissionSection.PAYOUTS, EPermissionAction.CREATE)
          }
        >
          Add Payout
        </Button>
      </Stack>

      <Table {...getTableProps()}>
        <TableHead sx={{ borderTopWidth: 2 }}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup) => (
                <TableCell
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {/* {headerGroups.map((group: HeaderGroup<{}>) => (
            <TableRow {...group.getHeaderGroupProps()}>
              {group.headers.map((column: HeaderGroup) => (
                <TableCell
                  style={{ width: "calc(100% / 7)" }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.canFilter ? column.render("Filter") : null}
                </TableCell>
              ))}
            </TableRow>
          ))} */}
          {sortingRow.length > 0 ? (
            sortingRow.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell: Cell) => (
                    <TableCell
                      {...cell.getCellProps([
                        { className: cell.column.className },
                      ])}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <EmptyTable msg="No Payouts" colSpan={columns.length} />
          )}
        </TableBody>
      </Table>
    </>
  );
}

// ==============================|| REACT TABLE - FILTERING ||============================== //

const PayoutsTable = () => {
  const { id } = useParams();
  const { getSinglePublisher } = usePublishers(undefined, id);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [selectedReportId, setSelectedReportId] = useState("");

  const deleteReport = async (reportId: string) => {
    const url = `${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/payouts/${id}/${reportId}`;

    try {
      const response = await fetch(url, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("__ac_token")}`,
        },
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.status} ${response.statusText}`);
      }

      const result = await response.json();
      console.log("Report deleted successfully:", result);
      enqueueSnackbar("Report Deleted Successfully!");
      setIsDeleteDialogOpen(false);
      getSinglePublisher.refetch();
    } catch (error) {
      console.error("Failed to delete the report:", error);
      // Handle the error, e.g., display an error message to the user
    }
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: "Transfer Reference",
          accessor: "reference",
        },
        {
          Header: "Transfer Date",
          accessor: "transferDate",
          Cell: ({ value }: { value: any }) => {
            return new Date(value).toLocaleDateString("en-UK");
          },
        },
        {
          Header: "Description",
          accessor: "description",
        },
        {
          Header: "Amount",
          accessor: "amount",
        },
        {
          Header: "Currency",
          accessor: "currency",
        },
        {
          Header: "Period",
          id: "period",
          accessor: "period",
          Cell: ({ value }: { value: any }) => {
            return `${new Date(value.startDate).toLocaleDateString(
              "en-UK"
            )} - ${new Date(value.endDate).toLocaleDateString("en-UK")}`;
          },
          width: 200,
        },
        {
          Header: "Download",
          accessor: "",
          filter: false,
          Filter: <></>,
          Cell: ({ row }: { row: Row }) => (
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={() => alert("Download Demo")}
              disabled
            >
              Download
            </Button>
          ),
        },
        {
          Header: "Delete",
          accessor: "",
          filter: false,
          Filter: <></>,
          Cell: ({ row }: { row: Row }) => (
            <Button
              variant="contained"
              color="error"
              size="small"
              onClick={() => {
                setIsDeleteDialogOpen(true);
                setSelectedReportId((row.original as any)._id);
              }}
            >
              Delete
            </Button>
          ),
        },
      ] as Column[],
    []
  );

  return (
    <MainCard content={false}>
      <AcConfirmDialog
        handleClose={() => setIsDeleteDialogOpen(false)}
        open={isDeleteDialogOpen}
        title="Are you sure you want to delete this Payout?"
        confirmText="Delete"
        onConfirm={() => deleteReport(selectedReportId)}
      />
      <ScrollX>
        <ReactTable
          columns={columns}
          data={
            ((getSinglePublisher?.data as any)?.payouts.reverse() || []) as any
          }
          refetchData={getSinglePublisher.refetch}
        />
      </ScrollX>
    </MainCard>
  );
};

export default PayoutsTable;
