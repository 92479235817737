import React, { useEffect, useState } from "react";
import {
  Drawer,
  Typography,
  Stack,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Button,
  Modal,
  CircularProgress,
  Box,
} from "@mui/material";
import { Publisher } from "types/publisher";
import MainCard from "components/MainCard";
import { CardContent } from "@mui/material";
import { CompanyData } from "sections/forms/wizard/validation-wizard/CompanyBaseDataForm";
import { useFormik } from "formik";
import * as yup from "yup";
import { enqueueSnackbar } from "notistack";
import ConfettiExplosion from "react-confetti-explosion";
import { useNavigate } from "react-router";
import useCompanies from "hooks/useCompanies";
import usePortals, { AddPortalPayload, Portal } from "hooks/usePortals";

const AddPortalDrawer = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  const { getCompanies } = useCompanies();

  useEffect(() => {
    fetch(
      `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/publishers`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("__ac_token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data: Publisher[]) => {
        setCompanies(
          Array.from(
            getCompanies.data?.map((company) => company.companyId) ?? []
          ).sort()
        );
      })
      .catch((error) => console.error(error));
  }, [getCompanies.data]);

  const navigate = useNavigate();

  const validationSchema = yup.object().shape({
    companyId: yup.string().required("Company is required"),
    portalDomain: yup.string().required("Domain is required"),
    portalName: yup.string().required("Portal name is required"),
  });

  const [companies, setCompanies] = useState<string[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isDoneCreatingPortal, setIsDoneCreatingPortal] = useState(false);
  const [finishedCreatingPortalClick, setFinishedCreatingPortalClick] =
    useState(false);
  const [newPortalData, setNewPortalData] = useState<Portal | null>(null);
  const { addPortalMutation } = usePortals();

  const onCreatePortal = (
    portalData: Partial<Portal>,
    onSuccess: Function,
    onError: (error: any) => void
  ) => {
    addPortalMutation.mutate(portalData as AddPortalPayload, {
      onSuccess: (response) => {
        onSuccess(response);
      },
      onError: (error) => {
        onError(error);
      },
    });
  };

  const formik = useFormik({
    validationSchema,
    initialValues: {
      companyId: "",
      portalDomain: "",
      portalName: "",
    },
    onSubmit: (values) => {
      setIsProcessing(true);
      values.portalDomain = values.portalDomain + ".appchargestore.com";
      onCreatePortal(
        values,
        (response: Portal) => {
          setIsDoneCreatingPortal(true);
          setNewPortalData(response);
        },
        (error) => {
          setIsProcessing(false);
          console.error("Error creating portal:", error);
          enqueueSnackbar("Error creating portal", {
            variant: "error",
          });
        }
      );
    },
  });

  function handleprojectNameOrCompanyNameChange() {
    if (!formik.values.companyId || !formik.values.portalName) return;
    formik.setFieldValue(
      "portalDomain",
      `${formik.values.companyId
        ?.toLowerCase()
        .replaceAll(" - ", "-")
        .replaceAll("_", "-")
        .replaceAll(" ", "-")}-${formik.values.portalName
        ?.toLowerCase()
        .replaceAll("_", "-")
        .replaceAll(" - ", "-")
        .replaceAll(" ", "-")}`
    );
    formik.setFieldValue(
      "adminUserMail",
      `appcharge-${formik.values.portalName
        ?.toLowerCase()
        .replaceAll(" - ", "-")
        .replaceAll(" ", "-")}@appcharge.com`
    );
  }

  function isValidCompanyName(str: string) {
    const regex = /^[a-zA-Z0-9- ]+$/;
    return regex.test(str);
  }

  return (
    <>
      <Modal
        open={isProcessing && !finishedCreatingPortalClick}
        onClose={() => {
          !isProcessing && setFinishedCreatingPortalClick(true);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MainCard title="Creating portal" modal darkTitle content={false}>
          <CardContent>
            {!isDoneCreatingPortal ? (
              <>
                <Typography id="modal-modal-description">
                  Your new portal is now being created... please wait.
                </Typography>
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  mt={3}
                  mb={-2}
                >
                  <CircularProgress />
                </Stack>
              </>
            ) : (
              <>
                <Stack justifyContent="center" alignItems="center">
                  <ConfettiExplosion zIndex={9999} />
                  <Typography id="modal-modal-description">
                    Your new portal <b>{formik.values.portalName}</b> has been
                    created successfully.
                  </Typography>
                  <Box mt={2}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        navigate(`/portals/${newPortalData?.portalId}`);
                      }}
                    >
                      View Your Newly Created Portal
                    </Button>
                  </Box>
                </Stack>
              </>
            )}
          </CardContent>
          <Stack
            direction="row"
            spacing={1}
            justifyContent="flex-end"
            sx={{ px: 2.5, py: 2 }}
          ></Stack>
        </MainCard>
      </Modal>
      <Drawer
        anchor="right"
        open={isOpen && !isProcessing && !isDoneCreatingPortal}
        onClose={() => {
          onClose?.();
        }}
        sx={{
          "& .MuiDrawer-paper": {
            width: {
              xs: "100%",
              sm: "75%",
              md: "550px",
            },
            padding: 3,
          },
        }}
      >
        <Typography variant="h4" gutterBottom sx={{ mb: 2 }}>
          Add a new Portal
        </Typography>
        <Stack>
          <Stack py={1} spacing={4}>
            <Stack spacing={1}>
              <InputLabel>Company</InputLabel>
              <FormControl fullWidth>
                <Select
                  placeholder="Company"
                  displayEmpty={true}
                  id="companyId"
                  name="companyId"
                  value={formik.values.companyId}
                  defaultValue=""
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleprojectNameOrCompanyNameChange();
                  }}
                  onBlur={formik.handleBlur}
                  autoComplete="off"
                >
                  <MenuItem value="" selected disabled>
                    <span style={{ color: "#b0b2b6" }}>Company *</span>
                  </MenuItem>
                  {companies.map((company) => (
                    <MenuItem key={company} value={company}>
                      {company}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <InputLabel>Portal Name</InputLabel>
              <TextField
                id="portalName"
                name="portalName"
                placeholder="Portal Name *"
                value={formik.values.portalName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.portalName && Boolean(formik.errors.portalName)
                }
                helperText={
                  formik.touched.portalName && formik.errors.portalName
                }
                fullWidth
              />
              <InputLabel>Portal Domain</InputLabel>
              <TextField
                id="portalDomain"
                name="portalDomain"
                placeholder="Domain *"
                value={formik.values.portalDomain}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.portalDomain &&
                  Boolean(formik.errors.portalDomain)
                }
                helperText={
                  formik.touched.portalDomain && formik.errors.portalDomain
                }
                fullWidth
                autoComplete="url"
                InputProps={{
                  endAdornment: ".appchargestore.com",
                }}
              />
            </Stack>
            <Stack spacing={1}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  formik.handleSubmit();
                  setIsProcessing(true);
                }}
                disabled={
                  !isValidCompanyName(formik.values.companyId) ||
                  !formik.isValid
                }
              >
                Add Portal
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
};

export default AddPortalDrawer;
