import { useState, SyntheticEvent } from "react";
import { useLocation, Link, Outlet, useParams } from "react-router-dom";

// material-ui
import {
  Box,
  Button,
  Drawer,
  Fade,
  FormControl,
  InputLabel,
  Skeleton,
  Stack,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// project-imports
import MainCard from "components/MainCard";

// assets
import {
  DocumentText,
  DollarSquare,
  Location,
  Map,
  Moneys,
  Profile,
  ReceiptText,
  RouteSquare,
  Setting3,
  Setting4,
  Shield,
  ShieldCross,
  ShieldSecurity,
  Task,
  User,
  Wallet,
} from "iconsax-react";
import usePublishers from "hooks/usePublishers";
import { EPermissionAction, EPermissionSection } from "utils/permissions/types";
import { usePermissions } from "utils/permissions/usePermissions";
import RestoreReceiptsDrawer from "./RestoreReceiptsDrawer";
import { LuEuro } from "react-icons/lu";
import EurFinancialReportDrawer from "./EurFinancialReportDrawer";
import { TfiLightBulb } from "react-icons/tfi";
import Tour from "reactour";
// ==============================|| PROFILE - ACCOUNT ||============================== //

const FinanceCenter = () => {
  const { pathname } = useLocation();

  let selectedTab = 0;
  switch (true) {
    case pathname.includes("/payment-methods"):
      selectedTab = 1;
      break;
    case pathname.includes("/routing-rules"):
      selectedTab = 2;
      break;
    case pathname.includes("/vat-report"):
      selectedTab = 5;
      break;
    case pathname.includes("/mapping"):
      selectedTab = 6;
      break;
  }

  const [value, setValue] = useState(selectedTab);
  const [isTourOpen, setIsTourOpen] = useState(false);

  const { id } = useParams();
  const { getSinglePublisher } = usePublishers(undefined, id);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    if (newValue === 3 || newValue === 4) {
      setValue(value);
      return;
    }
    setValue(newValue);
  };

  const [restoreReceiptsDrawerOpen, setRestoreReceiptsDrawerOpen] =
    useState(false);
  const [eurFinancialReportDrawerOpen, setEurFinancialReportDrawerOpen] =
    useState(false);
  const { hasPermission } = usePermissions();

  return (
    <>
      <RestoreReceiptsDrawer
        restoreReceiptsDrawerOpen={restoreReceiptsDrawerOpen}
        setRestoreReceiptsDrawerOpen={setRestoreReceiptsDrawerOpen}
      />
      <EurFinancialReportDrawer
        eurFinancialReportDrawerOpen={eurFinancialReportDrawerOpen}
        setEurFinancialReportDrawerOpen={setEurFinancialReportDrawerOpen}
      />
      <Tour
        steps={[
          {
            selector: "[aria-label='disputes-tab']",
            content:
              "This is the Dispute Center, here you can view and manage disputes.",
          },
          {
            selector: "[aria-label='payment-methods-tab']",
            content:
              "This is the Payment Methods, here you can view and manage payment methods for the entire environment.",
          },
          {
            selector: "[aria-label='routing-rules-tab']",
            content:
              "This is the Routing Rules, here you can view and manage routing rules for publishers and payment methods.",
          },
          {
            selector: "[aria-label='restore-receipts-tab']",
            content:
              "This is the Restore Receipts, here you can restore receipts for this environment.",
          },
        ]}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        className="custom-tour"
      />
      {!getSinglePublisher.isLoading && (
        <Fade key={"gametitle"} in={true} timeout={500}>
          <Stack direction="row" gap={2} mb={2} justifyContent="space-between">
            <Typography variant="h3">Payments Hub</Typography>
            {/* <Tooltip title="Take a tour" arrow>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setIsTourOpen(true);
                }}
              >
                <TfiLightBulb />
              </Button>
            </Tooltip> */}
            {/* <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              gap={1}
            >
              <Stack direction="row" alignItems="center" gap={1}>
                {(getSinglePublisher.data as StoreData)?.storeTheme?.general
                  ?.logo && (
                  <Box
                    width={35}
                    height={35}
                    bgcolor="white"
                    borderRadius={1.5}
                    justifyContent="center"
                    alignItems="center"
                    display="flex"
                    p={0.5}
                  >
                    <img
                      src={getSinglePublisher?.data?.storeTheme?.general?.logo}
                      alt="logo"
                      style={{ display: isImageLoaded ? "block" : "none" }} // Show image if loaded
                      onLoad={() => setIsImageLoaded(true)} // Set state to true on load
                      onError={(e) => {
                        const target = e.target as HTMLImageElement; // Cast to HTMLImageElement
                        target.style.display = "none"; // Hide on error
                      }}
                    />
                  </Box>
                )}
                <Typography variant="h4" color="primary">
                  {getSinglePublisher?.data?.publisher?.companyName}
                </Typography>
              </Stack>
            </Stack> */}
          </Stack>
        </Fade>
      )}
      <MainCard border={true}>
        {getSinglePublisher.isLoading ? (
          <>
            <Skeleton variant="rounded" height={120} />
            <Box mt={2}></Box>
            <Stack direction="row" height={600} gap={3}>
              <Skeleton variant="rounded" height={600} width="100%" />
            </Stack>
          </>
        ) : (
          <Fade key={"gameview"} in={true} timeout={500}>
            <div>
              <Box
                sx={{ borderBottom: 1, borderColor: "divider", width: "100%" }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="tabs"
                >
                  <Tab
                    label="Disputes"
                    component={Link}
                    to=""
                    icon={<Shield />}
                    iconPosition="start"
                    value={0}
                    aria-label="disputes-tab"
                    disabled={
                      !hasPermission(
                        EPermissionSection.DISPUTE_DATA_CENTER,
                        EPermissionAction.GET
                      )
                    }
                  />
                  <Tab
                    label="Payment Methods"
                    component={Link}
                    to={`payment-methods`}
                    icon={<Wallet />}
                    iconPosition="start"
                    disabled={
                      !hasPermission(
                        EPermissionSection.PAYOUTS,
                        EPermissionAction.GET
                      )
                    }
                    aria-label="payment-methods-tab"
                  />
                  <Tab
                    label="Routing Rules"
                    component={Link}
                    to={`routing-rules`}
                    icon={<Location />}
                    iconPosition="start"
                    aria-label="routing-rules-tab"
                  />
                  <Tab
                    label="Restore Receipts"
                    component={Link}
                    to=""
                    onClick={(e) => {
                      e.preventDefault();
                      setRestoreReceiptsDrawerOpen(true);
                    }}
                    icon={<ReceiptText />}
                    iconPosition="start"
                    aria-label="restore-receipts-tab"
                  />
                  <Tab
                    label="EUR financial report"
                    component={Link}
                    to=""
                    onClick={(e) => {
                      e.preventDefault();
                      setEurFinancialReportDrawerOpen(true);
                    }}
                    icon={<LuEuro />}
                    iconPosition="start"
                    aria-label="eur-financial-report-tab"
                  />
                  <Tab
                    label="VAT Report"
                    component={Link}
                    to="vat-report"
                    icon={<DocumentText />}
                    iconPosition="start"
                    aria-label="vat-report-tab"
                  />
                  <Tab
                    label="Mapping"
                    component={Link}
                    to="mapping"
                    icon={<Map />}
                    iconPosition="start"
                    aria-label="mapping-tab"
                    hidden={
                      !localStorage
                        .getItem("__ac_user")
                        ?.includes("shai@appcharge.com") &&
                      !localStorage
                        .getItem("__ac_user")
                        ?.includes("nir@appcharge.com")
                    }
                  />
                </Tabs>
              </Box>
              <Box sx={{ mt: 2.5 }}>
                <Outlet />
              </Box>
            </div>
          </Fade>
        )}
      </MainCard>
    </>
  );
};

export default FinanceCenter;
