import { useMutation, useQuery } from "@tanstack/react-query";
import { EQuery } from "enum/queries.enum";
import axiosServices from "utils/axios";

export interface Portal {
  portalId: string;
  portalName: string;
  companyId: string;
  portalDomain: string;
  stores: string[];
}

export interface AddPortalPayload {
  companyId: string;
  portalDomain: string;
  portalName: string;
}

const fetchPortals = async () => {
  const response = await axiosServices.get<Portal[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/portals`
  );
  return response.data;
};

const fetchSinglePortal = async (portalId: string) => {
  return await axiosServices.get<Portal>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/portals/${portalId}`
  );
};

const addPortal = async (portal: AddPortalPayload) => {
  return await axiosServices.post<Portal>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/portals`,
    portal
  );
};

const assignPortal = async ({
  portalId,
  publisherId,
}: {
  portalId: string;
  publisherId: string;
}) => {
  return await axiosServices.put<Portal>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/portals/${portalId}/assign-store/project/${publisherId}`
  );
};

export default function usePortals(portalId?: string) {
  const getPortals = useQuery<Portal[]>({
    queryKey: [EQuery.GET_PORTALS],
    queryFn: fetchPortals,
  });

  const getSinglePortal = useQuery({
    queryKey: [EQuery.GET_SINGLE_PORTAL, portalId],
    queryFn: () => fetchSinglePortal(portalId!),
    enabled: !!portalId,
  });

  const addPortalMutation = useMutation({
    mutationFn: addPortal,
  });

  const assignPortalMutation = useMutation({
    mutationFn: (params: { portalId: string; publisherId: string }) =>
      assignPortal(params),
  });

  return {
    getPortals,
    getSinglePortal,
    addPortalMutation,
    assignPortalMutation,
  };
}
