import { useMemo, useState } from "react";

// material-ui
import {
  Box,
  Button,
  Chip,
  Fade,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";

// third-party
import {
  useTable,
  useFilters,
  useGlobalFilter,
  Column,
  Row,
  HeaderGroup,
  Cell,
} from "react-table";

// project-imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { EmptyTable } from "components/third-party/ReactTable";

import {
  GlobalFilter,
  DefaultColumnFilter,
  SelectColumnFilter,
  renderFilterTypes,
} from "utils/react-table";
import { useNavigate } from "react-router";
import usePublishers from "hooks/usePublishers";
import { Copy, ExportSquare, More } from "iconsax-react";
import AcProgress from "components/AcProgress";
import axiosServices from "utils/axios";
import { enqueueSnackbar } from "notistack";
import Tour from "reactour";
import AddGameDrawer from "./AddPortalDrawer";
import { usePermissions } from "utils/permissions/usePermissions";
import { EPermissionAction, EPermissionSection } from "utils/permissions/types";
import usePortals from "hooks/usePortals";

function ReactTable({ columns, data }: { columns: Column[]; data: [] }) {
  const navigate = useNavigate();

  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const initialState = useMemo(
    () => ({ filters: [{ id: "status", value: "" }] }),
    []
  );
  const [isAddGameDrawerOpen, setIsAddGameDrawerOpen] = useState(false);
  const { hasPermission } = usePermissions();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      filterTypes,
    },
    useGlobalFilter,
    useFilters
  );

  const sortingRow = rows.slice(0, 150);

  return (
    <>
      <AddGameDrawer
        isOpen={isAddGameDrawerOpen}
        onClose={() => {
          setIsAddGameDrawerOpen(false);
        }}
      />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{ padding: 2 }}
      >
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows as any}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsAddGameDrawerOpen(true);
          }}
          disabled={
            !hasPermission(
              EPermissionSection.PUBLISHERS,
              EPermissionAction.CREATE
            )
          }
        >
          Add New Portal
        </Button>
      </Stack>

      <Table {...getTableProps()}>
        <TableHead sx={{ borderTopWidth: 2 }}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup) => (
                <TableCell
                  style={{
                    maxWidth: "calc(100% / 7)",
                    minWidth: "calc(100% / 7)",
                    width: "calc(100% / 7)",
                    overflow: "hidden",
                  }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {headerGroups.map((group: HeaderGroup<{}>) => (
            <TableRow {...group.getHeaderGroupProps()}>
              {group.headers.map((column: HeaderGroup) => (
                <TableCell
                  style={{
                    maxWidth: "calc(100% / 7)",
                    minWidth: "calc(100% / 7)",
                    width: "calc(100% / 7)",
                    overflow: "hidden",
                  }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.canFilter ? column.render("Filter") : null}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {sortingRow.length > 0 ? (
            sortingRow.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow
                  {...row.getRowProps()}
                  // onClick={() =>
                  //   navigate(`/publishers/${(row.original as any)._id}`)
                  // }
                  sx={{
                    cursor: "pointer",
                    "&:hover": { backgroundColor: "action.hover" },
                  }}
                >
                  {row.cells.map((cell: Cell) => (
                    <TableCell
                      {...cell.getCellProps([
                        { className: cell.column.className },
                      ])}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <EmptyTable msg="No Data" colSpan={7} />
          )}
        </TableBody>
      </Table>
    </>
  );
}

const PortalsTable = () => {
  const getCompanyFromURL = () => {
    const currentURL = window.location.href;
    const url = new URL(currentURL);
    const params = new URLSearchParams(url.search);
    const company = params.get("company");
    return company || undefined;
  };
  const [isFetchingCredentials, setIsFetchingCredentials] = useState(false);
  const [, setSelectedCompany] = useState("");
  const { getPortals } = usePortals();
  const [isTourOpen, setIsTourOpen] = useState(false);

  const handleLoginToPublishersDashboard = (companyId: string) => {
    setSelectedCompany(companyId);
    setIsFetchingCredentials(true);
    axiosServices
      .get(
        `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/companies/${companyId}/superuser`
      )
      .then((response) => {
        const env =
          window.location.href?.toLowerCase()?.includes("dev") ||
          window.location.href?.toLowerCase()?.includes("localhost")
            ? "-dev"
            : window.location.href?.toLowerCase()?.includes("staging")
            ? "-staging"
            : window.location.href?.toLowerCase()?.includes("sandbox")
            ? "-sandbox"
            : "";
        window.open(
          `https://dashboard${env}.appcharge.com/login?t=${response.data}`,
          "_blank"
        );
      })
      .catch((error) => {
        setTimeout(() => {
          enqueueSnackbar("Failed to fetch credentials", {
            variant: "error",
          });
        }, 1000);
      })
      .finally(() => {
        setTimeout(() => {
          setIsFetchingCredentials(false);
        }, 1000);
      });
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: "Portal ID",
          accessor: "portalId",
          className: "pid",
          Cell: ({ value }: { value: string }) => {
            return (
              <Stack direction="row" spacing={1} alignItems="center">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    navigator.clipboard.writeText(value);
                    enqueueSnackbar("Copied Portal ID to clipboard", {
                      variant: "default",
                    });
                  }}
                  size="small"
                >
                  <Copy />
                </IconButton>
                <Typography>{value}</Typography>
              </Stack>
            );
          },
        },
        {
          Header: "Company",
          accessor: "companyId",
          // Modify the Cell to use the value from getCompanyFromURL if it exists
          Cell: ({ value }: { value: string }) => {
            const companyFromURL = getCompanyFromURL();
            return companyFromURL || value || "N/A";
          },
          // Conditionally add Filter only if getCompanyFromURL() exists
          ...(getCompanyFromURL()
            ? {
                Filter: (column: any) => () => {
                  // Add free text filter
                  return DefaultColumnFilter({ ...column, id: "companyId" });
                  // return SelectColumnFilter([], { ...column, id: "companyId" });
                },
              }
            : {}),
        },
        {
          Header: "Portal Name",
          accessor: "portalName",
          className: "md-col",
        },
        {
          Header: "Portal Domain",
          accessor: "portalDomain",
          className: "md-plus-col",
          Cell: ({ value }: { value: string }) => {
            return (
              <Stack direction="row" spacing={1} alignItems="center">
                <IconButton
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open("https://" + value, "_blank");
                  }}
                  size="small"
                >
                  <ExportSquare />
                </IconButton>
                <Typography>{value}</Typography>
              </Stack>
            );
          },
        },
        {
          Header: "Connected Stores",
          accessor: "stores",
        },
      ] as Column[],
    []
  );

  return !getPortals.isLoading ? (
    <>
      <Tour
        steps={[
          {
            selector: ".MuiCard-root",
            content: "This is the publishers ID",
          },
        ]}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
      {isFetchingCredentials && (
        <AcProgress
          text="Fetching credentials and logging you in..."
          isFloating={true}
        />
      )}
      <Fade key={"publisherstitle"} in={true} timeout={500}>
        <Typography variant="h3" mb={getCompanyFromURL() ? 0 : 3}>
          Portals
        </Typography>
      </Fade>
      {getCompanyFromURL() && (
        <Fade key={"publisherssubtitle"} in={true} timeout={500}>
          <Typography variant="h4" color="primary" my={1.5} mt={2}>
            Company: {getCompanyFromURL()}
          </Typography>
        </Fade>
      )}
      <Fade key={"publishers"} in={true} timeout={500}>
        <MainCard content={false}>
          <ScrollX>
            <ReactTable
              columns={columns}
              data={(getPortals?.data || []) as any}
            />
          </ScrollX>
        </MainCard>
      </Fade>
    </>
  ) : (
    <>
      <Skeleton variant="rounded" height={120} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
    </>
  );
};

export default PortalsTable;
