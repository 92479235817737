export enum EQuery {
  GET_COMPANIES = "GET_COMPANIES",
  GET_PUBLISHERS = "GET_PUBLISHERS",
  GET_PUBLISHERS_BY_COMPANY = "GET_PUBLISHERS_BY_COMPANY",
  GET_SINGLE_COMPANY = "GET_SINGLE_COMPANY",
  LOGIN = "LOGIN",
  ADD_ADMIN_USER = "ADD_ADMIN_USER",
  GET_COMPANY_CREDS = "GET_COMPANY_CREDS",
  GET_SINGLE_PUBLISHER = "GET_SINGLE_PUBLISHER",
  GET_BLOCKED_PLAYERS = "GET_BLOCKED_PLAYERS",
  GET_FEES = "GET_FEES",
  GET_AUDITS = "GET_AUDITS",
  GET_ROLES = "GET_ROLES",
  GET_ROUTING_RULES = "GET_ROUTING_RULES",
  GET_PAYMENT_PROVIDERS = "GET_PAYMENT_PROVIDERS",
  GET_PUBLISHER_NOTE = "GET_PUBLISHER_NOTE",
  GET_PUBLISHER_SETTINGS = "GET_PUBLISHER_SETTINGS",
  GET_PUBLISHER_PRICE_POINTS = "GET_PUBLISHER_PRICE_POINTS",
  GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS",
  GET_DISPUTES = "GET_DISPUTES",
  GET_VAT_ENTITIES = "GET_VAT_ENTITIES",
  GET_VAT_REPORTS = "GET_VAT_REPORTS",
  DOWNLOAD_VAT_REPORT = "DOWNLOAD_VAT_REPORT",
  GET_PORTALS = "GET_PORTALS",
  GET_SINGLE_PORTAL = "GET_SINGLE_PORTAL",
  GET_ADMIN_USERS = "GET_ADMIN_USERS",
}
