import { useMemo, useState } from "react";

// material-ui
import {
  Button,
  Drawer,
  FormControl,
  InputLabel,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Select,
  MenuItem,
  CircularProgress,
  IconButton,
  Menu,
  Checkbox,
  Tooltip,
} from "@mui/material";

// third-party
import {
  useTable,
  useFilters,
  useGlobalFilter,
  Column,
  HeaderGroup,
  Cell,
  Row,
} from "react-table";

import { useFormik } from "formik";

// project-imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { EmptyTable } from "components/third-party/ReactTable";

import {
  GlobalFilter,
  DefaultColumnFilter,
  renderFilterTypes,
} from "utils/react-table";
import { Context } from "App";
import { StoreData } from "types/publisher";
import { enqueueSnackbar } from "notistack";
import { getStoreData } from "utils/apiCalls";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { toDate } from "date-fns";
import * as Yup from "yup";
import useBlockedPlayers from "hooks/useBlockedPlayers";
import { useParams } from "react-router";
import { More } from "iconsax-react";
import AcConfirmDialog from "components/AcConfirmDialog";

function ReactTable({
  columns,
  data,
  loading,
}: {
  columns: Column[];
  data: any[];
  loading: boolean;
}) {
  const { id } = useParams();
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const initialState = useMemo(
    () => ({ filters: [{ id: "status", value: "" }] }),
    []
  );
  const [addUserDrawerOpen, setAddUserDrawerOpen] = useState(false);
  const { addBlockedPlayers } = useBlockedPlayers(id);

  const validationSchema = Yup.object().shape({
    blockedPlayers: Yup.string().required("Required"),
    banReason: Yup.string().required("Required"),
    bannedUntil: Yup.string().required("Required"),
  });

  const formik = useFormik({
    initialValues: {
      blockedPlayers: "",
      banReason: "",
      banReasonDetails: "",
      bannedUntil: new Date("2030-12-31"),
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        const playerIds = values.blockedPlayers
          .split("\n")
          .map((id) => id.trim())
          .filter((id) => id !== ""); // Filter out empty lines
        const banReason =
          values.banReason === "Other"
            ? values.banReasonDetails
            : values.banReason;

        const blockedPlayersArray = playerIds.map((playerId) => ({
          publisherPlayerId: playerId,
          banReason,
          bannedUntil: values.bannedUntil.toISOString(),
        }));

        await addBlockedPlayers.mutateAsync(blockedPlayersArray);

        enqueueSnackbar("Players blocked successfully", { variant: "success" });
        setAddUserDrawerOpen(false);
        formik.resetForm();
      } catch (error) {
        enqueueSnackbar("Failed to block players", { variant: "error" });
        console.error("Error blocking players:", error);
      } finally {
        setSubmitting(false);
      }
    },
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      filterTypes,
    },
    useGlobalFilter,
    useFilters
  );

  const sortingRow = rows.slice(0, 10);

  return (
    <>
      <Drawer
        anchor="right"
        open={addUserDrawerOpen}
        onClose={() => setAddUserDrawerOpen(false)}
        sx={{
          "& .MuiDrawer-paper": {
            width: { xs: "100%", sm: "75%", md: "350px" },
            padding: 3,
          },
        }}
      >
        <Typography
          variant="h4"
          whiteSpace="normal"
          gutterBottom
          sx={{ mb: 2 }}
        >
          Block Player(s)
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Stack py={1} spacing={4}>
            <Stack spacing={1}>
              <InputLabel
                sx={{
                  wordBreak: "break-word",
                  overflowWrap: "break-word",
                }}
              >
                Publishers (Game) Player(s) Id(s)
                <Typography variant="subtitle2" width="100%">
                  For multiple players, separate player Ids with a line break
                </Typography>
              </InputLabel>
              <FormControl fullWidth>
                <TextField
                  multiline
                  rows={4}
                  {...formik.getFieldProps("blockedPlayers")}
                  error={
                    formik.touched.blockedPlayers &&
                    Boolean(formik.errors.blockedPlayers)
                  }
                  helperText={
                    formik.touched.blockedPlayers &&
                    formik.errors.blockedPlayers
                  }
                />
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                  py={1}
                  pl={1}
                >
                  For example:
                  <br />
                  <code>
                    6face106-3f89-4a3f-ae45-bc24957e5953
                    <br />
                    1fd8b09f-df8c-4ce5-b657-8cd336eb770c
                    <br />
                    9689a0a1-ca94-422b-abca-5e9bd121acfc
                    <br />
                    211b08dc-6b0c-4e41-b8cf-230ecb973d5f
                  </code>
                </Typography>
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <InputLabel>Block Reason</InputLabel>
              <Select
                {...formik.getFieldProps("banReason")}
                error={
                  formik.touched.banReason && Boolean(formik.errors.banReason)
                }
                onChange={(e) => {
                  formik.setFieldValue("banReason", e.target.value);
                  if (e.target.value !== "other") {
                    formik.setFieldValue("banReasonDetails", "");
                  }
                }}
              >
                <MenuItem value="" disabled>
                  <em>Select reason</em>
                </MenuItem>
                <MenuItem value={"Too many disputes"}>
                  Too many disputes
                </MenuItem>
                <MenuItem value={"High fraud risk"}>High fraud risk</MenuItem>
                <MenuItem value={"Requested by the publisher"}>
                  Requested by the publisher
                </MenuItem>
                <MenuItem value={"Requested by the player"}>
                  Requested by the player
                </MenuItem>
                <MenuItem value={"Other"}>Other</MenuItem>
              </Select>
              {formik.values.banReason === "Other" && (
                <FormControl fullWidth>
                  <TextField
                    multiline
                    rows={4}
                    {...formik.getFieldProps("banReasonDetails")}
                    error={
                      formik.touched.banReasonDetails &&
                      Boolean(formik.errors.banReasonDetails)
                    }
                    helperText={
                      formik.touched.banReasonDetails &&
                      formik.errors.banReasonDetails
                    }
                  />
                </FormControl>
              )}
            </Stack>
            <Stack spacing={1}>
              <InputLabel>Block Until</InputLabel>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    disablePast
                    format="dd/MM/yyyy"
                    value={formik.values.bannedUntil}
                    onChange={(newValue) =>
                      formik.setFieldValue("bannedUntil", newValue)
                    }
                  />
                </LocalizationProvider>
              </FormControl>
            </Stack>
            <Stack spacing={1}>
              <Button variant="contained" color="primary" type="submit">
                Block
              </Button>
            </Stack>
          </Stack>
        </form>
      </Drawer>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{ padding: 2 }}
      >
        <Stack direction="row" spacing={2}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows as any}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <Stack direction="row" spacing={0} alignItems="center">
            <Checkbox
              onChange={(e) => {
                setFilter(
                  "isBlocked",
                  e.target.checked ? "Unbanned" : "Banned"
                );
              }}
              id="show-unbanned-players"
            />
            <Tooltip
              title="Unbanned Players are players who were previously banned but have since been unbanned"
              arrow
              placement="top"
            >
              <Typography
                component="label"
                htmlFor="show-unbanned-players"
                sx={{ cursor: "pointer" }}
              >
                Show Only Unbanned Players
              </Typography>
            </Tooltip>
          </Stack>
        </Stack>

        <Button
          variant="contained"
          onClick={() => {
            setAddUserDrawerOpen(true);
          }}
        >
          Block Players
        </Button>
      </Stack>

      <Table {...getTableProps()}>
        <TableHead sx={{ borderTopWidth: 2 }}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup, index: number) => (
                <TableCell
                  style={{
                    width:
                      index === headerGroup.headers.length - 3
                        ? "30%" // Make the third-to-last column wider
                        : `calc(70% / ${headerGroup.headers.length - 1})`, // Distribute remaining width evenly
                  }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {loading ? (
            <TableRow>
              <TableCell colSpan={columns.length} align="center">
                <CircularProgress />
              </TableCell>
            </TableRow>
          ) : sortingRow.length > 0 ? (
            sortingRow.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell: Cell) => (
                    <TableCell
                      {...cell.getCellProps([
                        { className: cell.column.className },
                      ])}
                    >
                      {cell.render("Cell")}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })
          ) : (
            <EmptyTable msg="No Data" colSpan={columns.length} />
          )}
        </TableBody>
      </Table>
    </>
  );
}

// ==============================|| REACT TABLE - FILTERING ||============================== //

const BlockedPlayersTable = () => {
  const { id } = useParams();

  const { getBlockedPlayers, unblockPlayer } = useBlockedPlayers(id);
  const [isUnbanDialogOpen, setIsUnbanDialogOpen] = useState(false);
  const [currentBannedPlayer, setCurrentBannedPlayer] = useState("");

  const handleUnbanPlayer = async () => {
    await unblockPlayer.mutateAsync(currentBannedPlayer, {
      onSuccess: () => {
        setIsUnbanDialogOpen(false);
        getBlockedPlayers.refetch();
        enqueueSnackbar("Player unbanned successfully", { variant: "success" });
      },
      onError: () => {
        enqueueSnackbar("Failed to unban player", { variant: "error" });
      },
    });
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: "Publisher Player ID",
          accessor: "publisherPlayerId",
        },
        {
          Header: "Banned From",
          accessor: "banDate",
          Cell: ({ value }: { value: string }) => {
            return toDate(new Date(value)).toLocaleDateString("en-GB");
          },
        },
        {
          Header: "Banned By",
          accessor: "bannedBy",
        },
        {
          Header: "Banned Until",
          accessor: "bannedUntil",
          Cell: ({ value }: { value: string }) => {
            return toDate(new Date(value)).toLocaleDateString("en-GB");
          },
        },
        {
          Header: "Ban Reason",
          accessor: "banReason",
        },
        {
          Header: "Unbanned",
          accessor: "isBlocked",
          Cell: ({ value }: { value: string }) => {
            return value === "Unbanned" ? (
              <Typography color="error">Unbanned</Typography>
            ) : (
              <Typography color="success">Banned</Typography>
            );
          },
        },

        {
          Header: "Actions",
          accessor: "",
          filter: false,
          Filter: <></>,
          Cell: ({ row }: { row: Row }) => {
            const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
            const open = Boolean(anchorEl);

            const handleClick = (
              event: React.MouseEvent<HTMLButtonElement>
            ) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            };

            const handleClose = (event?: React.MouseEvent<HTMLElement>) => {
              if (event) {
                event.stopPropagation();
              }
              setAnchorEl(null);
            };

            const handleMenuItemClick =
              (action: () => void) => (event: React.MouseEvent) => {
                event.stopPropagation();
                action();
                handleClose();
              };

            return (
              <>
                <IconButton onClick={handleClick}>
                  <More />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(
                    event: {},
                    reason: "backdropClick" | "escapeKeyDown"
                  ) => handleClose()}
                  onClick={(event) => event.stopPropagation()}
                >
                  <MenuItem
                    onClick={handleMenuItemClick(() => {
                      setIsUnbanDialogOpen(true);
                      setCurrentBannedPlayer(
                        (row.original as any).publisherPlayerId
                      );
                    })}
                    disabled={(row.original as any).isBlocked === "Unbanned"}
                  >
                    Unban player
                  </MenuItem>
                </Menu>
              </>
            );
          },
        },
      ] as Column[],
    []
  );

  return (
    <MainCard content={false}>
      <AcConfirmDialog
        handleClose={() => setIsUnbanDialogOpen(false)}
        open={isUnbanDialogOpen}
        title={
          <span>
            Are you sure you want to unban player{" "}
            <b>
              <em>{currentBannedPlayer}</em>
            </b>
            ?
          </span>
        }
        confirmText="Unban"
        onConfirm={handleUnbanPlayer}
      />
      <ScrollX>
        <ReactTable
          columns={columns}
          data={
            (!getBlockedPlayers.isLoading &&
              getBlockedPlayers?.data?.data
                .map((a: any) => ({
                  ...a,
                  isBlocked: a.isBlocked ? "Banned" : "Unbanned",
                }))
                .sort(
                  (a: any, b: any) =>
                    new Date(b.banDate).getTime() -
                    new Date(a.banDate).getTime()
                )) ||
            []
          }
          loading={getBlockedPlayers.isLoading}
        />
      </ScrollX>
    </MainCard>
  );
};

export default BlockedPlayersTable;
