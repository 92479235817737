import * as React from "react";
import { Command } from "cmdk";
import {
  LuCalendar,
  LuCreditCard,
  LuSettings,
  LuSearch,
  LuCommand,
  LuX,
  LuBuilding2,
} from "react-icons/lu";
import usePublishers from "hooks/usePublishers";
import { Publisher } from "types/publisher";
import { useNavigate } from "react-router";
import useCompanies from "hooks/useCompanies";

const quickActions = [
  {
    id: "publishers",
    name: "Games",
    icon: LuCalendar,
    kbd: "⌘G",
    path: "/publishers",
  },
  {
    id: "companies",
    name: "Companies",
    icon: LuBuilding2,
    kbd: "⌘X",
    path: "/companies",
  },
  {
    id: "payments-hub",
    name: "Payments Hub",
    icon: LuCreditCard,
    kbd: "⌘P",
    path: "/finance/center",
  },
  {
    id: "routing-rules",
    name: "Routing Rules",
    icon: LuSettings,
    kbd: "⌘R",
    path: "/finance/center/routing-rules",
  },
];

export function Omnibar() {
  const [open, setOpen] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [prefix, setPrefix] = React.useState("");
  const inputRef = React.useRef<HTMLInputElement>(null);
  const navigate = useNavigate();
  const { getPublishers } = usePublishers();
  const { getCompanies } = useCompanies();

  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "k" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setOpen((open) => !open);
      }
    };

    const companiesDown = (e: KeyboardEvent) => {
      if (e.key === "x" && (e.metaKey || e.ctrlKey)) {
        e.preventDefault();
        setPrefix("Companies");
        setSearch("");
      }
    };

    const quickActionShortcuts = (e: KeyboardEvent) => {
      if (!open) return;

      if (e.metaKey || e.ctrlKey) {
        const key = e.key.toLowerCase();
        if (key === "k" || key === "x") return;

        const action = quickActions.find((a) =>
          a.kbd.toLowerCase().endsWith(key)
        );
        if (action) {
          e.preventDefault();
          navigate(action.path);
          setOpen(false);
        }
      }
    };

    document.addEventListener("keydown", down);
    document.addEventListener("keydown", companiesDown);
    document.addEventListener("keydown", quickActionShortcuts);
    return () => {
      document.removeEventListener("keydown", down);
      document.removeEventListener("keydown", companiesDown);
      document.removeEventListener("keydown", quickActionShortcuts);
    };
  }, [navigate, open]);

  // Close on escape
  React.useEffect(() => {
    const down = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        setOpen(false);
      }
    };

    document.addEventListener("keydown", down);
    return () => document.removeEventListener("keydown", down);
  }, []);

  // Focus input when opened
  React.useEffect(() => {
    if (open) {
      setSearch("");
      setPrefix("");
      setTimeout(() => {
        inputRef.current?.focus();
      }, 100);
    }
  }, [open]);

  return (
    <>
      <button
        onClick={() => setOpen(true)}
        className="hidden items-center gap-2 px-4 py-2 text-sm text-muted-foreground rounded-lg border border-input bg-background shadow-sm hover:bg-accent/10 transition-colors duration-200"
      >
        <LuSearch className="h-4 w-4" />
        <span>Search...</span>
        <kbd className="pointer-events-none ml-auto inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 text-[10px] font-medium text-muted-foreground opacity-100">
          <span className="text-xs">⌘</span>K
        </kbd>
      </button>

      <div
        className={`fixed inset-0 transition-all duration-300 bg-background/80 backdrop-blur-md ${
          open ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
        onClick={() => setOpen(false)}
      />
      <div
        className={`fixed left-[50%] top-[50%] z-50 translate-x-[-50%] translate-y-[-50%] w-full max-w-[640px] p-4 transition-all duration-300 ${
          open ? "opacity-100 visible" : "opacity-0 invisible"
        }`}
      >
        <Command
          className="bg-white rounded-lg border shadow-md w-full h-[400px]"
          loop
        >
          <div className="flex items-center px-3 border-b h-[60px] min-h-[60px]">
            <LuCommand className="mr-2 h-4 w-4 shrink-0 opacity-50" />
            <div className="flex-1 flex items-center">
              {prefix && (
                <span className="text-[11px] block text-muted-foreground text-gray-500 mr-2 bg-gray-100 rounded-md px-1 mt-1">
                  {prefix}
                </span>
              )}
              <Command.Input
                ref={inputRef}
                value={search}
                onValueChange={setSearch}
                placeholder="Type a command or search..."
                className="flex h-14 w-full rounded-md bg-transparent py-3 text-sm outline-none placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50"
              />
            </div>
            <button
              onClick={() => setOpen(false)}
              className="ml-2 p-1 rounded-md hover:bg-accent/10 transition-colors"
            >
              <LuX className="h-4 w-4 opacity-50" />
            </button>
          </div>

          <Command.List className="h-[340px] overflow-y-auto">
            <div className="p-4">
              <Command.Empty className="py-6 text-center text-sm text-muted-foreground">
                No results found.
              </Command.Empty>

              <Command.Group
                heading="Quick Actions"
                className="text-[#7265E6] text-center"
              >
                {quickActions.map((action) => (
                  <Command.Item
                    key={action.id}
                    onSelect={() => {
                      setOpen(false);
                      navigate(action.path);
                    }}
                    className="flex items-center gap-2 px-4 py-2 text-gray-500 hover:bg-[#efedfd] hover:text-[#7265E6] rounded-lg cursor-pointer"
                  >
                    <action.icon className="h-4 w-4" />
                    <span>{action.name}</span>
                    {action.kbd && (
                      <kbd className="pointer-events-none ml-auto inline-flex h-5 select-none items-center gap-1 rounded border bg-muted px-1.5 text-[10px] font-medium text-muted-foreground opacity-100">
                        <span className="text-xs">{action.kbd}</span>
                      </kbd>
                    )}
                  </Command.Item>
                ))}
              </Command.Group>

              {search !== "" && prefix === "" && (
                <Command.Group
                  heading="Games"
                  hidden={
                    getPublishers.isLoading ||
                    !getPublishers.data?.some((publisher) =>
                      publisher.companyName
                        .toLowerCase()
                        .includes(search.toLowerCase())
                    )
                  }
                  className="text-[#7265E6] text-center"
                >
                  {getPublishers.data
                    ?.filter(
                      (publisher) =>
                        publisher.companyName
                          .toLowerCase()
                          .includes(search.toLowerCase()) && search !== ""
                    )
                    .slice(0, 10)
                    .map((publisher: Publisher) => (
                      <Command.Item
                        className="text-left text-gray-600 py-2 px-4"
                        key={publisher._id}
                        onSelect={() => {
                          setOpen(false);
                          navigate(`/publishers/${publisher._id}`);
                        }}
                      >
                        {publisher.companyName}
                      </Command.Item>
                    ))}
                </Command.Group>
              )}

              {search !== "" && prefix === "Companies" && (
                <Command.Group
                  heading="Companies"
                  hidden={getCompanies.isLoading || !getCompanies.data}
                  className="text-[#7265E6] text-center"
                >
                  {(getCompanies.data as any[])?.map((company: any) => (
                    <Command.Item
                      key={company.companyId}
                      className="text-left text-gray-600 p-2 px-4 hover:bg-[#efedfd] hover:text-[#7265E6] rounded-lg cursor-pointer"
                      onSelect={() => {
                        setOpen(false);
                        navigate(`/publishers?company=${company.companyId}`);
                        window.location.reload();
                      }}
                    >
                      {company.companyId}
                    </Command.Item>
                  ))}
                </Command.Group>
              )}
            </div>
          </Command.List>
        </Command>
      </div>
    </>
  );
}
