import { useMemo, useState } from "react";

// material-ui
import {
  Box,
  Button,
  Fade,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";

// third-party
import {
  useTable,
  useFilters,
  useGlobalFilter,
  Column,
  Row,
  HeaderGroup,
  Cell,
} from "react-table";

// project-imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { EmptyTable } from "components/third-party/ReactTable";

import {
  GlobalFilter,
  DefaultColumnFilter,
  renderFilterTypes,
} from "utils/react-table";
import { More } from "iconsax-react";
import { enqueueSnackbar } from "notistack";
import { usePermissions } from "utils/permissions/usePermissions";
import { EPermissionAction, EPermissionSection } from "utils/permissions/types";
import useAdminUsers from "hooks/useAdminUsers";
import TableRowWithDetails from "./TableRowWithDetails";
import AddRoleDrawer from "components/admin/AddRoleDrawer";

// ==============================|| REACT TABLE ||============================== //

function ReactTable({
  columns,
  data,
  isAddRoleDrawerOpen,
  setIsAddRoleDrawerOpen,
  incomingPermissions,
  incomingRoleName,
  incomingRoleId,
  refetch,
}: {
  columns: Column[];
  data: [];
  isAddRoleDrawerOpen: boolean;
  setIsAddRoleDrawerOpen: (open: boolean) => void;
  incomingPermissions?: string[];
  incomingRoleName?: string;
  incomingRoleId?: string;
  refetch: () => void;
}) {
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(() => ({ Filter: DefaultColumnFilter }), []);
  const initialState = useMemo(
    () => ({ filters: [{ id: "status", value: "" }] }),
    []
  );
  const { hasPermission } = usePermissions();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      filterTypes,
    },
    useGlobalFilter,
    useFilters
  );

  const sortingRow = rows.slice(0, 150);

  return (
    <>
      <AddRoleDrawer
        refetch={refetch}
        open={isAddRoleDrawerOpen}
        onClose={() => setIsAddRoleDrawerOpen(false)}
        incomingPermissions={incomingPermissions}
        incomingRoleName={incomingRoleName}
        incomingRoleId={incomingRoleId}
      />
      <Stack
        direction="row"
        spacing={2}
        justifyContent="space-between"
        sx={{ padding: 2 }}
      >
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows as any}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setIsAddRoleDrawerOpen(true);
          }}
          disabled={
            !hasPermission(EPermissionSection.ROLES, EPermissionAction.CREATE)
          }
        >
          Add New Role
        </Button>
      </Stack>

      <Table {...getTableProps()}>
        <TableHead sx={{ borderTopWidth: 2 }}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: HeaderGroup) => (
                <TableCell
                  style={{
                    maxWidth: "calc(100% / 7)",
                    minWidth: "calc(100% / 7)",
                    width: "calc(100% / 7)",
                    overflow: "hidden",
                  }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.render("Header")}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableHead>
        <TableBody {...getTableBodyProps()}>
          {headerGroups.map((group: HeaderGroup<{}>) => (
            <TableRow {...group.getHeaderGroupProps()}>
              {group.headers.map((column: HeaderGroup) => (
                <TableCell
                  style={{
                    maxWidth: "calc(100% / 7)",
                    minWidth: "calc(100% / 7)",
                    width: "calc(100% / 7)",
                    overflow: "hidden",
                  }}
                  {...column.getHeaderProps([{ className: column.className }])}
                >
                  {column.canFilter ? column.render("Filter") : null}
                </TableCell>
              ))}
            </TableRow>
          ))}
          {sortingRow.length > 0 ? (
            sortingRow.map((row, i) => {
              prepareRow(row);
              return <TableRowWithDetails key={i} row={row} />;
            })
          ) : (
            <EmptyTable msg="No Data" colSpan={7} />
          )}
        </TableBody>
      </Table>
    </>
  );
}

// ==============================|| REACT TABLE - FILTERING ||============================== //

const RolesTableV2 = () => {
  const [isAddRoleDrawerOpen, setIsAddRoleDrawerOpen] = useState(false);
  const { getAdminRoles, deleteAdminRoleMutation } = useAdminUsers();
  const [selectedRole, setSelectedRole] = useState<{
    permissions: string[];
    roleId: string;
    presentationName: string;
  } | null>(null);
  const { hasPermission } = usePermissions();

  const handleDeleteRole = (roleId: string) => {
    deleteAdminRoleMutation.mutate(roleId, {
      onSuccess: () => {
        enqueueSnackbar("Role deleted successfully", {
          variant: "success",
        });
        setIsAddRoleDrawerOpen(false);
        getAdminRoles.refetch();
      },
      onError: (err) => {
        enqueueSnackbar(`Role couldn't be deleted: ${err.message}`, {
          variant: "error",
        });
      },
    });
  };

  const columns = useMemo(
    () =>
      [
        {
          Header: "Role Name",
          accessor: "presentationName",
        },
        {
          Header: "Actions",
          accessor: "",
          filter: false,
          Filter: <></>,
          Cell: ({ row }: { row: Row }) => {
            const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
            const open = Boolean(anchorEl);

            const handleClick = (
              event: React.MouseEvent<HTMLButtonElement>
            ) => {
              event.stopPropagation();
              setAnchorEl(event.currentTarget);
            };

            const handleClose = (event?: React.MouseEvent<HTMLElement>) => {
              if (event) {
                event.stopPropagation();
              }
              setAnchorEl(null);
            };

            const handleMenuItemClick =
              (action: () => void) => (event: React.MouseEvent) => {
                event.stopPropagation();
                action();
                handleClose();
              };

            return (
              <>
                <IconButton onClick={handleClick}>
                  <More />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={(
                    event: {},
                    reason: "backdropClick" | "escapeKeyDown"
                  ) => handleClose()}
                  onClick={(event) => event.stopPropagation()}
                >
                  <MenuItem
                    onClick={handleMenuItemClick(() => {
                      setSelectedRole({
                        presentationName: (row.original as any)
                          ?.presentationName,
                        permissions: (row.original as any)?.permissions,
                        roleId: (row.original as any)?._id,
                      });
                      setIsAddRoleDrawerOpen(true);
                    })}
                    disabled={
                      !hasPermission(
                        EPermissionSection.ROLES,
                        EPermissionAction.EDIT
                      )
                    }
                  >
                    Edit Role
                  </MenuItem>
                  <MenuItem
                    sx={{ color: "error.main" }}
                    onClick={() => handleDeleteRole((row.original as any)?._id)}
                    disabled={
                      !hasPermission(
                        EPermissionSection.ROLES,
                        EPermissionAction.DELETE
                      )
                    }
                  >
                    Delete Role
                  </MenuItem>
                </Menu>
              </>
            );
          },
        },
      ] as Column[],
    []
  );

  return !getAdminRoles.isLoading ? (
    <>
      <Fade key={"rolestitle"} in={true} timeout={500}>
        <Typography variant="h3" mb={3}>
          Roles
        </Typography>
      </Fade>
      <Fade key={"roles"} in={true} timeout={500}>
        <MainCard content={false}>
          <ScrollX>
            <ReactTable
              columns={columns}
              data={(getAdminRoles?.data?.data || []) as any}
              isAddRoleDrawerOpen={isAddRoleDrawerOpen}
              setIsAddRoleDrawerOpen={setIsAddRoleDrawerOpen}
              incomingPermissions={selectedRole?.permissions}
              incomingRoleName={selectedRole?.presentationName}
              incomingRoleId={selectedRole?.roleId}
              refetch={getAdminRoles.refetch}
            />
          </ScrollX>
        </MainCard>
      </Fade>
    </>
  ) : (
    <>
      <Skeleton variant="rounded" height={120} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
    </>
  );
};

export default RolesTableV2;
