import { useMutation, useQuery } from "@tanstack/react-query";
import { EQuery } from "enum/queries.enum";
import axiosServices from "utils/axios";

export interface VatEntity {
  id: string;
  countryArrival: string;
  countryDispatch: string;
  sellerName: string;
  vatNumber: string;
}

export type AddVatEntityPayload = Pick<
  VatEntity,
  "countryArrival" | "countryDispatch" | "sellerName" | "vatNumber"
>;

export interface VatReport {
  id: string;
  status: "CREATED" | "IN_PROGRESS" | "FAILED";
  interval: {
    startDate: string;
    endDate: string;
  };
  bucket: string;
  userId: string;
  countryCode: string;
}

export type AddVatReportPayload = Pick<VatReport, "userId" | "countryCode"> & {
  startDate: string;
  endDate: string;
};

const fetchVatReports = async () => {
  return await axiosServices.get<VatReport[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/vat-report`
  );
};

const fetchVatEntities = async () => {
  return await axiosServices.get<VatEntity[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/vat-entity`
  );
};

const deleteVatEntity = async (id: string) => {
  return await axiosServices.delete(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/vat-entity/${id}`
  );
};

const createVatEntity = async (vatEntity: AddVatEntityPayload) => {
  return await axiosServices.post(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/vat-entity`,
    vatEntity
  );
};

const createVatReport = async (vatReport: AddVatReportPayload) => {
  return await axiosServices.post(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/vat-report`,
    vatReport
  );
};

const deleteVatReport = async (id: string) => {
  return await axiosServices.delete(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/vat-report/${id}`
  );
};

const downloadVatReport = async (id: string) => {
  return await axiosServices.get(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/vat-report/${id}/download-link`
  );
};

const useDownloadVatReport = (id: string) =>
  useQuery({
    queryKey: [`${EQuery.DOWNLOAD_VAT_REPORT}-${id}`],
    queryFn: () => downloadVatReport(id),
    enabled: !!id,
  });

export default function useVatEntities() {
  const getVatEntities = useQuery({
    queryKey: [EQuery.GET_VAT_ENTITIES],
    queryFn: () => fetchVatEntities(),
  });

  const getVatReports = useQuery({
    queryKey: [EQuery.GET_VAT_REPORTS],
    queryFn: () => fetchVatReports(),
  });

  const createVatEntityMutation = useMutation({
    mutationFn: (vatEntity: AddVatEntityPayload) => createVatEntity(vatEntity),
  });

  const deleteVatEntityMutation = useMutation({
    mutationFn: (id: string) => deleteVatEntity(id),
  });

  const createVatReportMutation = useMutation({
    mutationFn: (vatReport: AddVatReportPayload) => createVatReport(vatReport),
  });

  const deleteVatReportMutation = useMutation({
    mutationFn: (id: string) => deleteVatReport(id),
  });

  return {
    getVatEntities,
    createVatEntityMutation,
    deleteVatEntityMutation,
    getVatReports,
    createVatReportMutation,
    deleteVatReportMutation,
    useDownloadVatReport,
  };
}
