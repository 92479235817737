import { useMemo } from "react";

import { useLocation } from "react-router-dom";

export function usePaginationFromURL(defaultPage = 0, defaultRows = 50) {
  const location = useLocation();
  const filters = useMemo(() => {
    const queryParams = new URLSearchParams(location.search);
    return DISPUTE_TABLE_FIELDS.reduce((acc, field) => {
      if (queryParams.has(field)) {
        acc[field] = queryParams.get(field) || "";
      }
      return acc;
    }, {} as Record<string, string>);
  }, [location.search]);
  const queryParams = new URLSearchParams(location.search);
  const page = Number(queryParams.get("page") || defaultPage);
  const rows = Number(queryParams.get("rows") || defaultRows);

  return {
    page,
    rows,
    filters,
  };
}

const DISPUTE_TABLE_FIELDS = [
  "disputeStatus",
  "disputeDueBy",
  "disputeCreationDate",
  "publisherGame",
  "publisherName",
  "orderId",
  "playerId",
  "playerEmail",
  "paymentProvider",
  "paymentProviderName",
  "providerPaymentId",
  "paymentId",
  "providerDisputeId",
  "publisherPurchaseId",
  "primaryPaymentMethod",
  "secondaryPaymentMethod",
  "amountTotal",
  "amountInUsd",
  "paymentCurrency",
  "pspReason",
  "pspReasonCode",
] as const;
