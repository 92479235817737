// third-party
// import { FormattedMessage } from "react-intl";

// assets
import { DollarCircle, Shield, WalletMoney, Devices } from "iconsax-react";

// type
import { NavItemType } from "types/menu";
import { EPermissionSection } from "utils/permissions/types";

// icons
const icons = {
  formsTable: WalletMoney,
  paymentMethods: WalletMoney,
  disputes: Shield,
  financeCenter: DollarCircle,
  web: Devices,
};

// ==============================|| MENU ITEMS - FORMS & TABLES ||============================== //

const portal: NavItemType = {
  id: "group-forms-tables",
  title: "Portals",
  icon: icons.formsTable,
  type: "group",
  children: [
    {
      id: "portal",
      title: "Portals",
      type: "item",
      url: "/portals",
      icon: icons.web,
      permissions: [EPermissionSection.PORTALS],
    },
  ],
};

export default portal;
