import { NavigateFunction } from "react-router-dom";

const updateQueryParam = ({
  params,
  location,
  navigate,
}: {
  params: {
    name: string;
    value: string;
  }[];
  location: Location;
  navigate: NavigateFunction;
}) => {
  const newParams = new URLSearchParams(location.search);

  const newParamNames = params.map((param) => param.name);

  newParams.forEach((_, key) => {
    if (!newParamNames.includes(key)) {
      newParams.delete(key);
    }
  });

  params.forEach((param) => {
    if (param.value?.length) {
      newParams.set(param.name, param.value);
    } else {
      newParams.delete(param.name);
    }
  });

  const navigationOpt: {
    replace: boolean;
    state?: Record<string, boolean>;
  } = { replace: true };
  navigate(`${location.pathname}?${newParams.toString()}`, navigationOpt);
};

export default updateQueryParam;
