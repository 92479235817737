import { useMutation } from "@tanstack/react-query";
import axiosServices from "utils/axios";

interface PaymentMethodMetadata {
  isCard?: boolean;
  cardLastFourDigits?: string;
  _id: string;
}

interface PaymentMethod {
  primaryMethod: string;
  secondaryMethod: string;
  metadata?: PaymentMethodMetadata;
  receiptDisplayName?: string | null;
  _id: string;
}

export interface Dispute {
  _id: string;
  disputeId: string;
  amountTotal: number;
  chargebackFee?: number;
  createdAt: string;
  orderId: string;
  paymentId: string;
  paymentMethod: PaymentMethod | null;
  paymentMethodId: string;
  paymentProvider: string;
  paymentProviderName: string;
  playerId: string;
  publisherId: string;
  publisherName: string;
  subMidId: string;
  transactionStatus?: string;
  updatedAt: string;
  disputeDueBy: string;
  pspReason: string;
  pspReasonCode: string;
}

interface PaginationParams {
  pageIndex: number;
  pageSize: number;
  filters: Record<string, string>;
}

const fetchDisputes = async ({
  pageIndex,
  pageSize,
  filters,
}: PaginationParams) => {
  return await axiosServices.post<Dispute[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/financial/dispute-data-center`,
    {
      page: pageIndex + 1,
      limit: pageSize,
      ...filters,
    }
  );
};

export default function useDisputes() {
  const getDisputes = useMutation({
    mutationFn: (paginationParams: PaginationParams) =>
      fetchDisputes(paginationParams),
  });

  return {
    getDisputes,
  };
}
