import { useQuery, useMutation } from "@tanstack/react-query";
import { EQuery } from "enum/queries.enum";
import axiosServices from "utils/axios";

const fetchAdminUsers = async () => {
  return await axiosServices.get<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/admin/users`
  );
};

const fetchAdminRoles = async () => {
  return await axiosServices.get<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/admin/users/roles`
  );
};

const addAdminUser = async ({
  email,
  role,
}: {
  email: string;
  role: string;
}) => {
  return await axiosServices.post<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/admin/users`,
    {
      userEmail: email,
      role,
    }
  );
};

const updateAdminUser = async ({
  email,
  role,
}: {
  email: string;
  role: string;
}) => {
  return await axiosServices.put<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/admin/users/${email}`,
    {
      role,
    }
  );
};

const deleteAdminUser = async (email: string) => {
  return await axiosServices.delete<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/admin/users/${email}`
  );
};

const addAdminRole = async ({
  presentationName,
  permissions,
}: {
  presentationName: string;
  permissions: string[];
}) => {
  return await axiosServices.post<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/admin/users/roles`,
    {
      presentationName,
      permissions,
    }
  );
};

const deleteAdminRole = async (roleName: string) => {
  return await axiosServices.delete<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/admin/users/roles/${roleName}`
  );
};

const updateAdminRole = async ({
  roleId,
  permissions,
}: {
  roleId: string;
  permissions: string[];
}) => {
  return await axiosServices.put<any[]>(
    `https:${process.env.REACT_APP_ADMIN_DASHBOARD_BASE_URL}/admin-dashboard/admin/users/roles/${roleId}`,
    {
      permissions,
    }
  );
};

export default function useAdminUsers() {
  const getAdminRoles = useQuery({
    queryKey: [EQuery.GET_ROLES],
    queryFn: fetchAdminRoles,
  });

  const getAdminUsers = useQuery({
    queryKey: [EQuery.GET_ADMIN_USERS],
    queryFn: fetchAdminUsers,
  });

  const addAdminUserMutation = useMutation({
    mutationFn: addAdminUser,
  });

  const updateAdminUserMutation = useMutation({
    mutationFn: updateAdminUser,
  });

  const deleteAdminUserMutation = useMutation({
    mutationFn: deleteAdminUser,
  });

  const addAdminRoleMutation = useMutation({
    mutationFn: addAdminRole,
  });

  const deleteAdminRoleMutation = useMutation({
    mutationFn: deleteAdminRole,
  });

  const updateAdminRoleMutation = useMutation({
    mutationFn: updateAdminRole,
  });

  return {
    getAdminUsers,
    addAdminUserMutation,
    deleteAdminUserMutation,
    updateAdminUserMutation,
    getAdminRoles,
    addAdminRoleMutation,
    deleteAdminRoleMutation,
    updateAdminRoleMutation,
  };
}
