import { useEffect, useMemo, useState } from "react";

// material-ui
import {
  Box,
  Chip,
  Fade,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Drawer,
} from "@mui/material";

// third-party
import {
  useTable,
  useFilters,
  useGlobalFilter,
  usePagination,
  Column,
  HeaderGroup,
  Cell,
} from "react-table";
import { useLocation, useNavigate } from "react-router-dom";

// project-imports
import MainCard from "components/MainCard";
import ScrollX from "components/ScrollX";
import { EmptyTable, TablePagination } from "components/third-party/ReactTable";

import {
  GlobalFilter,
  renderFilterTypes,
  DefaultColumnDebouncedFilter,
} from "utils/react-table";
import { Copy } from "iconsax-react";
import { enqueueSnackbar } from "notistack";
import useDisputes, { Dispute } from "hooks/useDisputes";
import { Button } from "@mui/material";
import { LuFullscreen } from "react-icons/lu";
import { usePaginationFromURL } from "hooks/usePaginationFromURL";
import updateQueryParam from "utils/update-query-param";
import AcLoading from "components/AcProgress";

// Add this constant near the top of the file, after imports
const SMALL_FONT_SIZE = "12px";

// ==============================|| REACT TABLE ||============================== //

function ReactTable({
  columns,
  data,
  isFullscreen,
  setIsFullscreen,
  isLoading,
  isInitialLoading,
  totalCount,
}: {
  columns: Column[];
  data: [];
  isFullscreen: boolean;
  isLoading: boolean;
  isInitialLoading: boolean;
  setIsFullscreen: (isFullscreen: boolean) => void;
  totalCount: number;
}) {
  const {
    page: initialPage,
    rows: initialRows,
    filters: initialFilters,
  } = usePaginationFromURL();
  const [selectedDispute, setSelectedDispute] = useState<Dispute | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const filterTypes = useMemo(() => renderFilterTypes, []);
  const defaultColumn = useMemo(
    () => ({ Filter: DefaultColumnDebouncedFilter }),
    []
  );
  const initialState = useMemo(
    () => ({
      filters: Object.keys(initialFilters).map((key) => ({
        id: key,
        value: initialFilters[key],
      })),
      pageIndex: initialPage,
      pageSize: initialRows,
    }),
    [initialPage, initialRows, initialFilters]
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    state: { pageIndex, pageSize, filters },
    setPageSize,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState,
      filterTypes,
      manualPagination: true,
      isLoading,
      pageCount: totalCount,
    },
    useGlobalFilter,
    useFilters,
    usePagination
  );

  useEffect(() => {
    const mappedFilters = filters.map((filter) => ({
      name: filter.id,
      value: filter.value.toString(),
    }));
    updateQueryParam({
      params: [
        { name: "page", value: pageIndex.toString() },
        { name: "rows", value: pageSize.toString() },
        ...mappedFilters,
      ],
      location: location as any,
      navigate,
    });
  }, [pageIndex, pageSize, filters]);

  const sortingRow = rows.slice(0, 150);

  if (isInitialLoading) return <TableSkeleton />;

  return (
    <Fade key={"publishers"} in={true} timeout={500}>
      <Box>
        <Drawer
          open={isDrawerOpen}
          onClose={() => {
            setIsDrawerOpen(false);
            setTimeout(() => {
              setSelectedDispute(null);
            }, 500);
          }}
          anchor="right"
          PaperProps={{
            sx: {
              width: "350px",
            },
          }}
        >
          <Stack direction="column" spacing={2} p={4}>
            <Typography variant="h4" color="primary">
              Dispute {selectedDispute?.disputeId}
            </Typography>
            <Stack direction="column" spacing={2}>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>Amount:</strong> $
                {selectedDispute?.amountTotal.toFixed(2)}
              </Typography>
              {selectedDispute?.chargebackFee && (
                <Typography variant="subtitle1" fontWeight="normal">
                  <strong>Chargeback Fee:</strong> $
                  {selectedDispute.chargebackFee.toFixed(2)}
                </Typography>
              )}
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>Created:</strong>{" "}
                {selectedDispute?.createdAt &&
                  new Date(selectedDispute.createdAt).toLocaleString(
                    undefined,
                    {
                      hour12: false,
                    }
                  )}
              </Typography>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>Order ID:</strong> {selectedDispute?.orderId}
              </Typography>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>Payment ID:</strong> {selectedDispute?.paymentId}
              </Typography>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>Payment Method:</strong>{" "}
                {selectedDispute?.paymentMethod
                  ? `${selectedDispute.paymentMethod.primaryMethod}${
                      selectedDispute.paymentMethod.metadata?.cardLastFourDigits
                        ? ` (*${selectedDispute.paymentMethod.metadata.cardLastFourDigits})`
                        : ""
                    }`
                  : "-"}
              </Typography>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>Payment Provider:</strong>{" "}
                {selectedDispute?.paymentProvider}
              </Typography>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>Player ID:</strong> {selectedDispute?.playerId}
              </Typography>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>Publisher:</strong> {selectedDispute?.publisherName}
              </Typography>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>SubMID ID:</strong> {selectedDispute?.subMidId}
              </Typography>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>Transaction Status:</strong>{" "}
                {selectedDispute?.transactionStatus || "Unknown"}
              </Typography>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>Due By:</strong>{" "}
                {(selectedDispute?.disputeDueBy &&
                  new Date(selectedDispute.disputeDueBy).toLocaleDateString(
                    "en-GB"
                  )) ||
                  "N/A"}
              </Typography>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>PSP Reason:</strong>{" "}
                {selectedDispute?.pspReason || "N/A"}
              </Typography>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>PSP Reason Code:</strong>{" "}
                {selectedDispute?.pspReasonCode || "N/A"}
              </Typography>
              <Typography variant="subtitle1" fontWeight="normal">
                <strong>Last Updated:</strong>{" "}
                {selectedDispute?.updatedAt &&
                  new Date(selectedDispute.updatedAt).toLocaleString(
                    undefined,
                    {
                      hour12: false,
                    }
                  )}
              </Typography>
            </Stack>
          </Stack>
        </Drawer>
        <Stack direction="row" spacing={2} sx={{ padding: 2 }}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows as any}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              setIsFullscreen(!isFullscreen);
              document.querySelector(".sidebar")?.classList.toggle("hidden");
              document.querySelector(".header")?.classList.toggle("hidden");
            }}
          >
            <LuFullscreen />
          </Button>
        </Stack>

        <ScrollX>
          <Table {...getTableProps()} size="small">
            <TableHead sx={{ borderTopWidth: 2 }}>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column: HeaderGroup) => (
                    <TableCell
                      style={{
                        minWidth: 150,
                        overflow: "hidden",
                        fontSize: isFullscreen ? SMALL_FONT_SIZE : undefined,
                      }}
                      {...column.getHeaderProps([
                        { className: column.className },
                      ])}
                    >
                      {column.render("Header")}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {headerGroups.map((group: HeaderGroup<{}>) => (
                <TableRow {...group.getHeaderGroupProps()}>
                  {group.headers.map((column: HeaderGroup) => (
                    <TableCell
                      style={{
                        minWidth: 150,
                        overflow: "hidden",
                        fontSize: isFullscreen ? SMALL_FONT_SIZE : undefined,
                      }}
                      {...column.getHeaderProps([
                        { className: column.className },
                      ])}
                    >
                      {column.canFilter ? column.render("Filter") : null}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
              {isLoading && (
                <TableCell
                  colSpan={columns.length}
                  sx={{
                    height: "420px",
                    position: "relative",
                    border: "none",
                  }}
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    <AcLoading />
                  </Box>
                </TableCell>
              )}
              {!isLoading &&
                (sortingRow.length > 0 ? (
                  <>
                    {sortingRow.map((row, i) => {
                      prepareRow(row);
                      return (
                        <TableRow
                          {...row.getRowProps()}
                          onClick={() => {
                            setSelectedDispute(row.original as Dispute);
                            setIsDrawerOpen(true);
                          }}
                          sx={{
                            cursor: "pointer",
                            "&:hover": { backgroundColor: "action.hover" },
                            padding: 0,
                          }}
                        >
                          {row.cells.map((cell: Cell) => (
                            <TableCell
                              {...cell.getCellProps([
                                { className: cell.column.className },
                              ])}
                              style={{
                                fontSize: isFullscreen
                                  ? SMALL_FONT_SIZE
                                  : undefined,
                              }}
                            >
                              {cell.render("Cell")}
                            </TableCell>
                          ))}
                        </TableRow>
                      );
                    })}
                  </>
                ) : (
                  <EmptyTable msg="No Data" colSpan={columns.length} />
                ))}
            </TableBody>
          </Table>
        </ScrollX>
        <Box sx={{ p: 2, pb: 2, width: "100%" }}>
          <TablePagination
            gotoPage={gotoPage}
            totalCount={totalCount}
            setPageSize={setPageSize}
            pageSize={pageSize}
            pageIndex={pageIndex}
          />
        </Box>
      </Box>
    </Fade>
  );
}

// ==============================|| REACT TABLE - FILTERING ||============================== //

const DisputesTable = () => {
  const { getDisputes } = useDisputes();
  const [isLoading, setIsLoading] = useState(true);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const { page, rows, filters } = usePaginationFromURL();

  useEffect(() => {
    getDisputes.mutate(
      { pageIndex: page, pageSize: rows, filters },
      {
        onSuccess: () => setIsLoading(false),
      }
    );
  }, [page, rows, filters]);

  const columns = useMemo(
    () => [
      {
        Header: "Dispute Status",
        accessor: "disputeStatus",
        Cell: ({ value }: { value: string | undefined }) => (
          <Chip
            label={value || "Unknown"}
            size="small"
            variant="light"
            color={
              value === "COMPLETED"
                ? "success"
                : value === "FAILED"
                ? "error"
                : "default"
            }
          />
        ),
      },
      {
        Header: "Due By",
        accessor: "disputeDueBy",
        Cell: ({ value }: { value: string }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {value ? new Date(value).toLocaleDateString("en-GB") : "-"}
          </Typography>
        ),
      },
      {
        Header: "Dispute created",
        accessor: "disputeCreationDate",
        Cell: ({ value }: { value: string }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {value
              ? new Date(value).toLocaleString(undefined, { hour12: false })
              : "-"}
          </Typography>
        ),
      },
      {
        Header: "Game Name",
        accessor: "publisherGame",
      },
      {
        Header: "Company name",
        accessor: "publisherName",
      },
      {
        Header: "Order ID",
        accessor: "orderId",
        Cell: ({ value }: { value: string }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(value);
                enqueueSnackbar("Copied Order ID to clipboard", {
                  variant: "default",
                });
              }}
              size="small"
            >
              <Copy />
            </IconButton>
            <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
              {value}
            </Typography>
          </Stack>
        ),
      },
      {
        Header: "Player ID",
        accessor: "playerId",
      },
      {
        Header: "Player Email",
        accessor: "playerEmail",
      },
      {
        Header: "Provider",
        accessor: "paymentProvider",
      },
      {
        Header: "Sub Mid",
        accessor: "paymentProviderName",
      },
      {
        Header: "External payment reference",
        accessor: "providerPaymentId",
        Cell: ({ value }: { value: string }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(value);
                enqueueSnackbar(
                  "Copied External Payment Reference to clipboard",
                  {
                    variant: "default",
                  }
                );
              }}
              size="small"
            >
              <Copy />
            </IconButton>
            <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
              {value}
            </Typography>
          </Stack>
        ),
      },
      {
        Header: "Payment ID",
        accessor: "paymentId",
        Cell: ({ value }: { value: string }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(value);
                enqueueSnackbar("Copied Payment ID to clipboard", {
                  variant: "default",
                });
              }}
              size="small"
            >
              <Copy />
            </IconButton>
            <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
              {value}
            </Typography>
          </Stack>
        ),
      },
      {
        Header: "External Dispute ID",
        accessor: "providerDisputeId",
        Cell: ({ value }: { value: string }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(value);
                enqueueSnackbar("Copied External Dispute ID to clipboard", {
                  variant: "default",
                });
              }}
              size="small"
            >
              <Copy />
            </IconButton>
            <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
              {value}
            </Typography>
          </Stack>
        ),
      },
      {
        Header: "Publisher Purchase ID",
        accessor: "publisherPurchaseId",
        Cell: ({ value }: { value: string }) => (
          <Stack direction="row" spacing={1} alignItems="center">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(value);
                enqueueSnackbar("Copied Publisher Purchase ID to clipboard", {
                  variant: "default",
                });
              }}
              size="small"
            >
              <Copy />
            </IconButton>
            <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
              {value}
            </Typography>
          </Stack>
        ),
      },
      {
        Header: "Payment method Primary",
        accessor: "primaryPaymentMethod",
        Cell: ({ value }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {value ? `${value}` : "-"}
          </Typography>
        ),
      },
      {
        Header: "Payment method Secondary",
        accessor: "secondaryPaymentMethod",
        Cell: ({ value }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {value ? `${value}` : "-"}
          </Typography>
        ),
      },
      {
        Header: "Amount Total (in local)",
        accessor: "amountTotal",
        Cell: ({ value }: { value: number }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {(value / 100).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        ),
      },
      {
        Header: "Amount Total (in USD)",
        accessor: "amountInUsd",
        Cell: ({ value }: { value: number }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            $
            {(value / 100).toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}
          </Typography>
        ),
      },
      {
        Header: "Currency",
        accessor: "paymentCurrency",
      },
      // {
      //   Header: "Payment 4 Last Digits",
      //   accessor: "cardLastFourDigits",
      //   Cell: ({ row }: { row: Row }) => {
      //     return (
      //       <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
      //         {(row.original as any)?.paymentMethod?.metadata
      //           ?.cardLastFourDigits || "-"}
      //       </Typography>
      //     );
      //   },
      // },
      {
        Header: "Reason",
        accessor: "pspReason",
        Cell: ({ value }: { value: string }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {value}
          </Typography>
        ),
      },
      {
        Header: "Reason Code",
        accessor: "pspReasonCode",
        Cell: ({ value }: { value: string }) => (
          <Typography fontSize={isFullscreen ? SMALL_FONT_SIZE : undefined}>
            {value}
          </Typography>
        ),
      },
    ],
    [isFullscreen]
  );

  return (
    <MainCard
      content={false}
      style={
        isFullscreen
          ? {
              position: "fixed",
              top: 0,
              left: 0,
              width: "100vw",
              height: "100vh",
              padding: 2,
              zIndex: 1000,
            }
          : undefined
      }
    >
      <ScrollX
        style={{
          height: "100%",
        }}
      >
        <ReactTable
          isFullscreen={isFullscreen}
          setIsFullscreen={setIsFullscreen}
          columns={columns as Column[]}
          isLoading={getDisputes.isPending}
          isInitialLoading={isLoading}
          totalCount={(getDisputes.data?.data as any)?.total}
          data={(getDisputes.data?.data as any)?.disputesData || []}
        />
      </ScrollX>
    </MainCard>
  );
};

function TableSkeleton() {
  return (
    <>
      <Skeleton variant="rounded" height={120} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
      <Box mt={2}></Box>
      <Skeleton variant="rounded" height={60} />
    </>
  );
}

export default DisputesTable;
